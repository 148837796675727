/**
 * Return the singular or plural version of a word as appropriate for the given
 * count value. By default, the plural form appends the suffix 's' to the
 * singular form. Override the plural version by giving a third param with a
 * suffix denoted as '+<suffix>', or the plural version.
 *
 * Examples:
 *
 *     pluralise(1, "cat") // "cat"
 *     pluralise(2, "cat") // "cats"
 *
 *     pluralise(2, "sandwich", "+es") // "sandwiches"
 *
 *     pluralise(1, "man", "men") // "man"
 *     pluralise(0, "man", "men") // "men"
 */
export function pluralise(
	count: number,
	singular: string,
	suffixOrPlural = "+s",
) {
	// Return singular if count is 1
	if (count === 1) {
		return singular;
	}

	// If a suffix is provided, as denoted by '+', append suffix to singluar...
	if (suffixOrPlural?.startsWith("+")) {
		return singular + suffixOrPlural.slice(1);
	}
	// ...otherwise return the plural form
	else {
		return suffixOrPlural;
	}
}

/**
 * Return a string including the count value followed by the pluralised word
 * generated by the `pluralise()` function.
 */
export function pluraliseWithCount(
	count: number,
	singular: string,
	suffixOrPlural = "+s",
) {
	return `${count} ${pluralise(count, singular, suffixOrPlural)}`;
}
