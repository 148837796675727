/*
 * Experimenting with Zustand library for global state management instead of
 * React Context, see:
 * - https://docs.pmnd.rs/zustand/
 * - https://tkdodo.eu/blog/working-with-zustand
 */
import { create } from "zustand";

interface SiteErrorStore {
	error: Error | null;
	actions: {
		showSiteError: (error: Error) => void;
		resetSiteError: () => void;
	};
}

// Define store but don't export it, export more specific hooks below
const useSiteErrorStore = create<SiteErrorStore>()((set) => ({
	error: null,
	actions: {
		showSiteError: (error: Error) => set({ error }),
		resetSiteError: () => set({ error: null }),
	},
}));

export const useSiteError = () => useSiteErrorStore((state) => state.error);

export const useSiteErrorActions = () =>
	useSiteErrorStore((state) => state.actions);
