import { z } from "zod";

import { differenceInMinutes, differenceInSeconds } from "date-fns";

import {
	TicketOptionSchema,
	WebSessionWithExpirationsSchema,
} from "./tessituraApiSchemas";

export interface CartExpirationStats {
	cartCreatedAt: Date;
	cartCreatedSecondsAgo: number;
	cartExiresAt: Date;
	cartExpiresInSeconds: number;
	cartExpiresInMMSS: string;
}

export function keyForTicketOption(
	ticketOption: z.infer<typeof TicketOptionSchema>,
) {
	const performanceOrPackageId =
		ticketOption.item.type === "performance"
			? ticketOption.item.performanceId
			: ticketOption.item.packageId;
	return `${performanceOrPackageId}-${ticketOption.priceTypeId}-${ticketOption.zoneId}`;
}

/**
 * Count the total number of tickets available across all ticket options., where
 * each zone is counted only once
 */
export function sumAvailableTicketOptions(
	ticketOptions?: Array<z.infer<typeof TicketOptionSchema>>,
) {
	const countByZoneId = new Map<number, number>();

	ticketOptions?.forEach((ticketOption) => {
		countByZoneId.set(Number(ticketOption.zoneId), ticketOption.availableCount);
	});

	return Array.from(countByZoneId.values()).reduce(
		(sum, countByZone) => sum + countByZone,
		0,
	);
}

export function calculateCartExpirationStats(
	webSessionWithExpirations:
		| z.infer<typeof WebSessionWithExpirationsSchema>
		| undefined
		| null,
): CartExpirationStats | null {
	if (
		!webSessionWithExpirations?.CartCreation ||
		!webSessionWithExpirations?.CartExpiration
	) {
		return null;
	}

	const now = new Date();

	const cartCreatedAt = new Date(webSessionWithExpirations.CartCreation);
	const cartCreatedSecondsAgo = differenceInSeconds(now, cartCreatedAt);

	const expiration = new Date(webSessionWithExpirations.CartExpiration);

	const cartExpiresInSeconds = differenceInSeconds(expiration, now);

	const minutes = differenceInMinutes(expiration, now);
	const seconds = Math.floor(cartExpiresInSeconds - 60 * minutes);
	const cartExpiresInMMSS =
		String(minutes).padStart(2, "0") + ":" + String(seconds).padStart(2, "0");

	return {
		cartCreatedAt,
		cartCreatedSecondsAgo,
		cartExiresAt: new Date(expiration),
		cartExpiresInSeconds,
		cartExpiresInMMSS,
	};
}
