import { z } from "zod";

export const IntegerSchema = z.number().int();

export const EmailSchema = z.string().email();

export const UrlSchema = z.string().url();

export const ApiIdSchema = z.number().int().or(z.bigint());

export const ApiIdOrIdsSchema = ApiIdSchema.or(ApiIdSchema.array());

// Match upstream type change of currency amount fields to `number` only
export const CurrencyAmountSchema = z.coerce.number();

export const DateishSchema = z.date().or(z.string().datetime({ offset: true }));

export const WebSessionKeySchema = z.string();

export const NameValuesSchema = z.map(z.string(), z.string());

export const EntitySchema = z.object({
	Id: ApiIdSchema,
});

export const EntitySummarySchema = EntitySchema.extend({
	Description: z.string(),
});

export const TypeSummarySchema = EntitySummarySchema.extend({
	Inactive: z.boolean(),
});

export const CountrySummarySchema = TypeSummarySchema;

export const SalutationTypeSummarySchema = TypeSummarySchema;

export const AddressTypeSummarySchema = TypeSummarySchema;

export const PhoneTypeSummarySchema = TypeSummarySchema;

export const SectionSummarySchema = TypeSummarySchema;

export const StateSummarySchema = TypeSummarySchema.extend({
	StateCode: z.string(),
	Country: CountrySummarySchema,
});

export const AddressSummarySchema = EntitySchema.extend({
	AddressType: AddressTypeSummarySchema,
});

export const ZoneGroupSummarySchema = EntitySchema.extend({
	AliasDescription: z.string(),
	Rank: IntegerSchema,
});

export const ConstituentGroupSummarySchema = TypeSummarySchema;

export const ConstituentTypeSummarySchema = TypeSummarySchema.extend({
	ConstituentGroup: ConstituentGroupSummarySchema,
});

export const EmarketIndicatorSummarySchema = TypeSummarySchema;

export const InactiveReasonSummarySchema = TypeSummarySchema;

export const ConstituentInactiveSummarySchema = TypeSummarySchema;

export const MailIndicatorSummarySchema = TypeSummarySchema;

export const NameStatusSummarySchema = TypeSummarySchema;

export const OriginalSourceSummarySchema = TypeSummarySchema;

export const PhoneIndicatorSummarySchema = TypeSummarySchema;

export const PrefixSummarySchema = TypeSummarySchema;

export const GenderSummarySchema = TypeSummarySchema;

export const SuffixSummarySchema = TypeSummarySchema;

export const AliasTypeSummarySchema = TypeSummarySchema;

export const OrderCategorySummarySchema = TypeSummarySchema;

export const LoginTypeSummarySchema = TypeSummarySchema;

export const ElectronicAddressTypeSchema = TypeSummarySchema.extend({
	IsEmail: z.boolean(),
});

export const ElectronicAddressTypeSummarySchema = TypeSummarySchema.extend({
	IsEmail: z.boolean(),
});

export const ConstituentProtectionTypeSummarySchema = TypeSummarySchema;

export const AddressCreateSchema = z.object({
	PrimaryIndicator: z.boolean(),
	Street1: z.string(),
	Street2: z.string().optional(),
	Street3: z.string().optional(),
	City: z.string().optional(),
	PostalCode: z.string(),
	Label: z.boolean(),
	Months: z.string(),
	State: EntitySchema,
	Country: EntitySchema,
	AddressType: EntitySchema,
});

export const AddressUpdateSchema = AddressCreateSchema.extend({
	Id: ApiIdSchema,
	UpdatedDateTime: DateishSchema,
	Constituent: EntitySchema,
});

export const AliasCreateSchema = z.object({
	Constituent: EntitySchema,
	AliasFirstName: z.string(),
	AliasLastName: z.string(),
	AliasType: EntitySchema,
	EditIndicator: z.boolean(),
});

export const AliasUpdateSchema = EntitySchema.extend({
	UpdatedDateTime: DateishSchema,
});

export const AliasSchema = AliasUpdateSchema.extend({
	CreatedBy: z.string(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	UpdatedBy: z.string(),
});

export const ElectronicAddressSchema = EntitySchema.extend({
	Address: z.string(),
	AffiliatedConstituent: EntitySchema,
	AltSalutationType: SalutationTypeSummarySchema,
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	Constituent: EntitySchema,
	ElectronicAddressType: ElectronicAddressTypeSchema,
	EndDate: DateishSchema,
	AllowHtmlFormat: z.boolean(),
	Inactive: z.boolean(),
	UpdatedDateTime: DateishSchema,
	UpdatedBy: z.string(),
	AllowMarketing: z.boolean(),
	Months: z.string(),
	PrimaryIndicator: z.boolean(),
	StartDate: DateishSchema,
	EditIndicator: z.boolean(),
	IsFromAffiliation: z.boolean(),
	IsEmail: z.boolean(),
});

export const ElectronicAddressCreateSchema = z.object({
	PrimaryIndicator: z.boolean(),
	Address: z.string(),
	Inactive: z.boolean(),
	AllowMarketing: z.boolean(),
	Months: z.string(),
	ElectronicAddressType: EntitySchema,
});

export const EmailAttachmentSchema = z.object({
	Name: z.string(),
	AttachmentBytes: z.any(),
});

export const EmailEmbeddedImageSchema = z.object({
	Name: z.string(),
	ImageBytes: z.any(),
	MediaType: z.string(),
});

export const ParameterValueSchema = z.object({
	Name: z.string(),
	Value: z.string(),
});

export const SalutationCreateSchema = z.object({
	SalutationType: EntitySchema,
	LetterSalutation: z.string(),
	EnvelopeSalutation1: z.string(),
	BusinessTitle: z.string(),
	DefaultIndicator: z.boolean(),
	EnvelopeSalutation2: z.string(),
	EditIndicator: z.boolean(),
	IsFromAffiliation: z.boolean(),
	Label: z.boolean(),
});

export const SalutationUpdateSchema = EntitySchema.extend({
	Constituent: EntitySchema,
	UpdatedDateTime: DateishSchema,
});

export const SalutationSchema = SalutationUpdateSchema.extend({
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	UpdatedBy: z.string(),
});

export const ConstituentCreateSchema = z.object({
	FirstName: z.string(),
	LastName: z.string(),
	SortName: z.string().nullish(),
	ConstituentType: EntitySchema,
	Addresses: AddressCreateSchema.array(),
	ElectronicAddresses: ElectronicAddressCreateSchema.array(),
	OriginalSource: EntitySchema,
});

export const ConstituentUpdateSchema = EntitySchema.extend({
	UpdatedDateTime: DateishSchema,
	FirstName: z.string(),
	LastName: z.string(),
	SortName: z.string().nullish(),
	ConstituentType: EntitySchema,
	OriginalSource: EntitySchema,
});

export const ConstituentSchema = ConstituentUpdateSchema.extend({
	ConstituentType: ConstituentTypeSummarySchema,
	OriginalSource: OriginalSourceSummarySchema.nullish(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	DisplayName: z.string(),
	EmarketIndicator: EmarketIndicatorSummarySchema.nullish(),
	Inactive: ConstituentInactiveSummarySchema,
	InactiveReason: InactiveReasonSummarySchema.nullish(),
	LastActivityDate: DateishSchema,
	LastGiftDate: DateishSchema.nullish(),
	LastTicketDate: DateishSchema,
	UpdatedBy: z.string(),
	MailIndicator: MailIndicatorSummarySchema.nullish(),
	MiddleName: z.string().nullish(),
	NameStatus: NameStatusSummarySchema.nullish(),
	PhoneIndicator: PhoneIndicatorSummarySchema.nullish(),
	Prefix: PrefixSummarySchema,
	Gender: GenderSummarySchema.nullish(),
	Suffix: SuffixSummarySchema,
	ProtectionType: ConstituentProtectionTypeSummarySchema.nullish(),
});

export const AddressSchema = AddressUpdateSchema.extend({
	Constituent: EntitySchema,
	State: StateSummarySchema.nullish(),
	Country: CountrySummarySchema,
	AddressType: AddressTypeSummarySchema,
	Description: z.string().nullish(),
	AffiliatedConstituent: EntitySchema.nullish(),
	AltSalutationType: SalutationTypeSummarySchema.nullish(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	DeliveryPoint: z.string(),
	EndDate: DateishSchema,
	GeoArea: IntegerSchema,
	Inactive: z.boolean(),
	UpdatedBy: z.string(),
	NcoaAction: IntegerSchema,
	NcoaSession: IntegerSchema,
	PostalCodeFormatted: z.string(),
	StartDate: DateishSchema,
	EditIndicator: z.boolean(),
	IsFromAffiliation: z.boolean(),
});

export const PhoneSchema = z.object({
	Address: AddressSummarySchema,
	AllowTelemarketing: z.boolean(),
	Constituent: EntitySchema,
	CreatedBy: z.string(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	DayEveningIndicator: z.string(),
	EditIndicator: z.boolean(),
	Id: ApiIdSchema,
	Inactive: z.boolean(),
	PhoneFormatted: z.string(),
	PhoneNumber: z.string(),
	PhoneSearch: z.string(),
	PhoneType: PhoneTypeSummarySchema,
	UpdatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
});

export const ConstituentDetailSchema = ConstituentSchema.extend({
	Addresses: AddressSchema.array(),
	ConstituentType: ConstituentTypeSummarySchema,
	ElectronicAddresses: ElectronicAddressSchema.array(),
	OriginalSource: OriginalSourceSummarySchema.nullish(),
	PhoneNumbers: PhoneSchema.array(),
	Salutations: SalutationSchema.array(),
});

export const ConstituentDisplaySummarySchema = EntitySchema.extend({
	DisplayName: z.string(),
	SortName: z.string().nullish(),
});

export const CustomDataItemSchema = z.object({
	Name: z.string(),
	Index: IntegerSchema,
	KeywordId: IntegerSchema,
	IsDropdown: z.boolean(),
	Value: z.string(),
	DataType: z.string(),
	Description: z.string(),
	EditIndicator: z.boolean(),
});

export const CartPricingRuleSummarySchema = EntitySummarySchema.extend({
	RuleAction: IntegerSchema,
});

export const CartPricingRuleMessageSchema = z.object({
	Id: ApiIdSchema,
	Message: z.string(),
	IsMessageOnlyRule: z.boolean(),
	NewRuleIndicator: z.string(),
	MessageType: EntitySummarySchema,
	PricingRule: CartPricingRuleSummarySchema,
});

export const CartFeeSummarySchema = z.object({
	FeeId: ApiIdSchema,
	Description: z.string(),
	Category: EntitySummarySchema,
});

export const CartFeeDetailSchema = z.object({
	Id: ApiIdSchema,
	LineItemId: ApiIdSchema,
	SubLineItemId: ApiIdSchema,
	FeeSummary: CartFeeSummarySchema,
	Amount: CurrencyAmountSchema,
	OverrideIndicator: z.string(),
	OverrideAmount: CurrencyAmountSchema,
	DbStatus: IntegerSchema,
});

export const PaymentPlanCardSchema = z.object({
	PaymentMethodGroupId: ApiIdSchema,
	Name: z.string(),
	Number: z.string(),
	ExpiryMonth: IntegerSchema,
	ExpiryYear: IntegerSchema,
});

export const PaymentPlanSchema = z.object({
	Id: ApiIdSchema,
	AmountDue: CurrencyAmountSchema,
	AccountId: ApiIdSchema,
	DateDue: DateishSchema,
	BillingType: EntitySummarySchema,
	Card: PaymentPlanCardSchema,
});

export const CartPaymentMethodSchema = EntitySummarySchema.extend({
	AccountType: EntitySummarySchema.nullish(),
	PaymentType: EntitySummarySchema,
});

export const CartPaymentSchema = EntitySchema.extend({
	Amount: CurrencyAmountSchema,
	TenderedAmount: CurrencyAmountSchema,
	PaymentMethod: CartPaymentMethodSchema,
	LastFourCreditCardNumber: z.string(),
	PayerName: z.string().nullish(),
	CheckNumber: z.string().nullish(),
	GiftCertificateNumber: z.string().nullish(),
	Applied: z.boolean(),
	Notes: z.string().nullish(),
});

export const CartPackageSchema = EntitySummarySchema.extend({
	Code: z.string(),
	Season: EntitySummarySchema,
	Type: EntitySummarySchema,
	FixedPackage: z.boolean(),
	NFSPackage: z.boolean(),
	SuperPackage: z.boolean(),
	SubPackage: z.boolean(),
	Date: DateishSchema,
});

export const ZoneMapSummarySchema = TypeSummarySchema.extend({
	SeatMap: EntitySummarySchema,
});

export const BestSeatMapSummarySchema = EntitySummarySchema.extend({
	IsGA: z.boolean(),
});

export const CartPerformanceSchema = EntitySummarySchema.extend({
	Code: z.string(),
	Facility: EntitySummarySchema,
	PerformanceDateTime: DateishSchema,
	Duration: IntegerSchema.nullish(),
	ProductionSeason: EntitySummarySchema,
	Season: EntitySummarySchema,
	TimeSlot: EntitySummarySchema,
	Type: EntitySummarySchema,
	ZoneMap: ZoneMapSummarySchema,
	BestSeatMap: BestSeatMapSummarySchema,
});

export const CartPriceTypeSchema = EntitySummarySchema.extend({
	Id: ApiIdSchema,
	Description: z.string(),
	ShortDescription: z.string(),
	Category: EntitySummarySchema,
	PriceTypeReason: EntitySummarySchema.nullish(),
	AliasDescription: z.string().nullish(),
});

export const CartSubLineItemDetailSchema = EntitySchema.extend({
	Id: ApiIdSchema,
	BenevolentIndicator: z.boolean(),
	CampaignId: ApiIdSchema,
	DiscountType: EntitySummarySchema.nullish(),
	DueAmount: CurrencyAmountSchema,
	PaidAmount: CurrencyAmountSchema,
	OriginalPrice: CurrencyAmountSchema,
	PerformancePriceTypeLayerId: ApiIdSchema,
});

export const CartZoneSchema = EntitySummarySchema.extend({
	ZoneGroupId: ApiIdSchema,
	ShortDescription: z.string(),
	Abbreviation: z.string(),
	Rank: IntegerSchema,
	ZoneTime: z.string(),
});

export const CartSeatSectionSchema = EntitySummarySchema.extend({
	ShortDescription: z.string(),
	PrintDescription: z.string().nullish(),
});

export const CartSeatSchema = EntitySchema.extend({
	Row: z.string(),
	Number: z.string(),
	Section: CartSeatSectionSchema,
});

export const CartSubLineItemSchema = EntitySchema.extend({
	Id: ApiIdSchema,
	ApplyPricing: z.boolean(),
	DbStatus: IntegerSchema,
	PriceType: CartPriceTypeSchema,
	OriginalPriceType: CartPriceTypeSchema,
	StatusId: ApiIdSchema,
	SubLineItemDetails: CartSubLineItemDetailSchema.array(),
	Zone: CartZoneSchema,
	Seat: CartSeatSchema,
	Recipient: ConstituentDisplaySummarySchema.nullish(),
	DueAmount: CurrencyAmountSchema,
	PaidAmount: CurrencyAmountSchema,
	SubLineItemFees: CartFeeDetailSchema.array().nullish(),
	NewRuleIndicator: z.string(),
	RuleIndicator: z.string().nullish(),
	PricingRule: CartPricingRuleSummarySchema.nullish(),
});

export const SpecialRequestSchema = z.object({
	StartPrice: CurrencyAmountSchema,
	EndPrice: CurrencyAmountSchema,
	WheelchairSeats: IntegerSchema.nullish(),
	AisleSeat: z.string().nullish(),
	ContiguousSeats: IntegerSchema.nullish(),
	StartingRow: z.string().nullish(),
	EndingRow: z.string().nullish(),
	StartingSeat: z.string().nullish(),
	EndingSeat: z.string().nullish(),
	HoldCode: IntegerSchema.nullish(),
	NoStairs: z.boolean().nullish(),
	LeaveSingleSeats: z.boolean(),
	Notes: z.string().nullish(),
	Category: EntitySummarySchema.nullish(),
});

const _BaseCartLineItemSchema = EntitySchema.extend({
	Id: ApiIdSchema,
	LineItemGroupId: ApiIdSchema,
	PackageLineItemId: ApiIdSchema,
	SuperPackageId: ApiIdSchema,
	AlternateUpgrade: z.string(),
	Primary: z.boolean(),
	DbStatus: IntegerSchema,
	Source: EntitySummarySchema,
	Package: CartPackageSchema.nullish(),
	Performance: CartPerformanceSchema.nullish(),
	PerformanceGroup: EntitySummarySchema.nullish(),
	SubLineItems: CartSubLineItemSchema.array().nullish(),
	SpecialRequest: SpecialRequestSchema,
	TotalDue: CurrencyAmountSchema,
	DueAmount: CurrencyAmountSchema,
});

type CartLineItemType = z.infer<typeof _BaseCartLineItemSchema> & {
	AlternateLineItems: Array<CartLineItemType> | null;
};

// CartLineItemSchema has a recursive field AlternateLineItems that requires q
// lot of jiggery-pokery, see https://github.com/colinhacks/zod#recursive-types
export const CartLineItemSchema: z.ZodType<CartLineItemType> =
	_BaseCartLineItemSchema.extend({
		AlternateLineItems: z.lazy(() => CartLineItemSchema.array().or(z.null())),
	});

export const AlternateLineItemSchema = CartLineItemSchema;

export const CartProductPerformanceSchema = EntitySchema.extend({
	LineItem: CartLineItemSchema,
});

export const CartProductPackageSchema = EntitySchema.extend({
	LineItems: CartLineItemSchema.array(),
});

export const CartContributionSchema = EntitySchema.extend({
	DbStatus: IntegerSchema,
	Amount: CurrencyAmountSchema,
	Fund: EntitySummarySchema,
	CustomDataItems: CustomDataItemSchema.array(),
	Notes: z.string(),
});

export const CartMembershipSchema = EntitySchema.extend({
	DbStatus: IntegerSchema,
	Amount: CurrencyAmountSchema,
	Fund: EntitySummarySchema,
	MembershipOrganization: EntitySummarySchema,
	MembershipLevel: EntitySummarySchema,
	CustomDataItems: CustomDataItemSchema.array(),
});

export const CartGiftCertificateSchema = z.object({
	PaymentId: ApiIdSchema,
	GiftCertificateNumber: z.string(),
	Amount: CurrencyAmountSchema,
	Notes: z.string(),
	Name: z.string(),
	Applied: z.boolean(),
});

export const CartProductSchema = z.object({
	ProductClass: EntitySummarySchema,
	Performance: CartProductPerformanceSchema.nullish(),
	Package: CartProductPackageSchema.nullish(),
	Contribution: CartContributionSchema.nullish(),
	Membership: CartMembershipSchema.nullish(),
	GiftCertificate: CartGiftCertificateSchema.nullish(),
	UserDefinedFee: CartFeeDetailSchema.nullish(),
	ProductGrouping: z.string(),
});

export const CartSchema = z.object({
	AmountPaidNow: CurrencyAmountSchema,
	AmountPaidPreviously: CurrencyAmountSchema,
	Appeal: EntitySummarySchema.nullish(),
	BalanceToCharge: CurrencyAmountSchema,
	BatchId: ApiIdSchema.nullish(),
	BookingId: ApiIdSchema.nullish(),
	CartAmount: CurrencyAmountSchema,
	CartFirstChoiceAmount: CurrencyAmountSchema,
	CartPrimaryAmount: CurrencyAmountSchema,
	CartWasPriced: z.boolean(),
	Constituent: ConstituentDisplaySummarySchema,
	CustomDataItems: CustomDataItemSchema.array(),
	DbStatus: IntegerSchema,
	DeliveryMethod: EntitySummarySchema,
	FeesAmount: CurrencyAmountSchema,
	FirstSeatAddedDateTime: DateishSchema,
	Id: ApiIdSchema,
	Initiator: ConstituentDisplaySummarySchema.nullish(),
	Messages: CartPricingRuleMessageSchema.array().nullish(),
	ModeOfSale: EntitySummarySchema,
	OrderCategory: OrderCategorySummarySchema.nullish(),
	OrderDateTime: DateishSchema,
	OrderFees: CartFeeDetailSchema.array().nullish(),
	OrderNotes: z.string().nullish(),
	PaymentPlans: PaymentPlanSchema.array().nullish(),
	Payments: CartPaymentSchema.array().nullish(),
	Products: CartProductSchema.array().nullish(),
	SessionKey: WebSessionKeySchema,
	Solicitor: z.string(),
	Source: EntitySummarySchema,
	SubTotal: CurrencyAmountSchema,
});

export const CartInfoSchema = z.object({
	PerformanceCount: IntegerSchema,
	PackageCount: IntegerSchema,
	ContributionCount: IntegerSchema,
	MembershipCount: IntegerSchema,
	UserDefinedFeeCount: IntegerSchema,
	GiftCertificateCount: IntegerSchema,
	PaymentCount: IntegerSchema,
	FirstSeatAddedDateTime: DateishSchema,
});

export const PricingRuleMessageTypeSummarySchema = TypeSummarySchema;

export const PricingRuleMessageSchema = EntitySchema.extend({
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	Inactive: z.boolean(),
	UpdatedDateTime: DateishSchema,
	UpdatedBy: z.string(),
	Message: z.string(),
	MessageType: PricingRuleMessageTypeSummarySchema,
	PricingRule: EntitySummarySchema,
	IsFromMessageOnlyRule: z.boolean(),
});

export const OrderProductViewFeeSummarySchema = z.object({
	FeeId: ApiIdSchema,
	Description: z.string(),
	Category: EntitySummarySchema,
});

export const OrderProductViewFeeDetailSchema = EntitySchema.extend({
	LineItemId: IntegerSchema,
	SubLineItemId: IntegerSchema,
	FeeSummary: OrderProductViewFeeSummarySchema,
	Amount: CurrencyAmountSchema,
	OverrideIndicator: z.string(),
	OverrideAmount: CurrencyAmountSchema,
});

export const OrderProductViewPaymentPlanSchema = EntitySchema.extend({
	AmountDue: CurrencyAmountSchema,
	DateDue: DateishSchema,
	BillingType: EntitySummarySchema,
});

export const OrderProductViewPaymentMethodSchema = EntitySummarySchema.extend({
	AccountType: EntitySummarySchema,
});

export const OrderProductViewPaymentSchema = EntitySchema.extend({
	Amount: CurrencyAmountSchema,
	TenderedAmount: CurrencyAmountSchema,
	PaymentMethod: OrderProductViewPaymentMethodSchema,
	LastFourCreditCardNumber: z.string(),
	PayerName: z.string(),
	CheckNumber: z.string(),
	GiftCertificateNumber: z.string(),
	Notes: z.string(),
});

export const OrderProductViewPackageSchema = EntitySchema.extend({
	Code: z.string(),
	Description: z.string(),
	Season: EntitySummarySchema,
	Type: EntitySummarySchema,
	FixedPackage: z.boolean(),
	NFSPackage: z.boolean(),
	SuperPackage: z.boolean(),
	SubPackage: z.boolean(),
	Date: DateishSchema,
});

export const OrderProductViewZoneMapSchema = EntitySummarySchema.extend({
	SeatMap: EntitySummarySchema,
	Inactive: z.boolean(),
});

export const OrderProductViewBestSeatMapSchema = EntitySummarySchema.extend({
	IsGA: z.boolean(),
});

export const OrderProductViewPerformanceSchema = EntitySummarySchema.extend({
	Code: z.string(),
	Facility: EntitySummarySchema,
	PerformanceDateTime: DateishSchema,
	Duration: IntegerSchema,
	ProductionSeason: EntitySummarySchema,
	Season: EntitySummarySchema,
	TimeSlot: EntitySummarySchema,
	Type: EntitySummarySchema,
	ZoneMap: OrderProductViewZoneMapSchema,
	BestSeatMap: OrderProductViewBestSeatMapSchema,
});

export const OrderProductViewPriceTypeSchema = EntitySummarySchema.extend({
	ShortDescription: z.string(),
	Category: EntitySummarySchema,
	PriceTypeReason: EntitySummarySchema.nullish(),
	AliasDescription: z.string(),
});

export const OrderProductViewSubLineItemDetailSchema = EntitySchema.extend({
	BenevolentIndicator: z.boolean(),
	CampaignId: ApiIdSchema,
	DiscountType: EntitySummarySchema.nullish(),
	DueAmount: CurrencyAmountSchema,
	PaidAmount: CurrencyAmountSchema,
	OriginalPrice: CurrencyAmountSchema,
	PerformancePriceTypeLayerId: ApiIdSchema,
});

export const OrderProductViewZoneSchema = EntitySummarySchema.extend({
	ZoneGroupId: ApiIdSchema,
	ShortDescription: z.string(),
	Abbreviation: z.string(),
	Rank: IntegerSchema,
	ZoneTime: z.string(),
});

export const OrderProductViewSeatSectionSchema = EntitySummarySchema.extend({
	ShortDescription: z.string(),
	PrintDescription: z.string().nullish(),
});

export const OrderProductViewSeatSchema = EntitySchema.extend({
	Row: z.string(),
	Number: z.string(),
	Section: OrderProductViewSeatSectionSchema,
});

export const OrderPricingRuleSummarySchema = EntitySummarySchema.extend({
	RuleAction: IntegerSchema,
});

export const OrderProductViewSubLineItemSchema = EntitySchema.extend({
	PriceType: OrderProductViewPriceTypeSchema,
	OriginalPriceType: OrderProductViewPriceTypeSchema,
	StatusId: ApiIdSchema,
	SubLineItemDetails: OrderProductViewSubLineItemDetailSchema.array(),
	Zone: OrderProductViewZoneSchema,
	Seat: OrderProductViewSeatSchema,
	Recipient: ConstituentDisplaySummarySchema,
	DueAmount: CurrencyAmountSchema,
	PaidAmount: CurrencyAmountSchema,
	TicketNumber: IntegerSchema,
	CompositeTicketNumber: IntegerSchema,
	SubLineItemFees: OrderProductViewFeeDetailSchema.array(),
	RuleIndicator: z.string(),
	PricingRule: OrderPricingRuleSummarySchema,
});

export const OrderProductViewAlternateLineItemSchema = EntitySchema.extend({
	LineItemGroupId: ApiIdSchema,
	PackageLineItemId: ApiIdSchema,
	SuperPackageId: ApiIdSchema,
	AlternateUpgrade: z.string(),
	Primary: z.boolean(),
	Package: OrderProductViewPackageSchema,
	Performance: OrderProductViewPerformanceSchema,
	SubLineItems: OrderProductViewSubLineItemSchema.array(),
	SpecialRequest: SpecialRequestSchema,
	TotalDue: CurrencyAmountSchema,
	DueAmount: CurrencyAmountSchema,
});

export const OrderProductViewPerformanceGroupSchema = EntitySummarySchema;

export const OrderProductViewLineItemSchema = EntitySchema.extend({
	AlternateLineItems: OrderProductViewAlternateLineItemSchema.array(),
	LineItemGroupId: ApiIdSchema,
	PackageLineItemId: ApiIdSchema,
	SuperPackageId: ApiIdSchema,
	AlternateUpgrade: z.string(),
	Primary: z.boolean(),
	Source: EntitySummarySchema,
	Package: OrderProductViewPackageSchema,
	Performance: OrderProductViewPerformanceSchema,
	PerformanceGroup: OrderProductViewPerformanceGroupSchema,
	SubLineItems: OrderProductViewSubLineItemSchema.array(),
	SpecialRequest: SpecialRequestSchema,
	TotalDue: CurrencyAmountSchema,
	DueAmount: CurrencyAmountSchema,
});

export const OrderProductViewProductPerformanceSchema = EntitySchema.extend({
	LineItem: OrderProductViewLineItemSchema,
});

export const OrderProductViewProductPackageSchema = EntitySchema.extend({
	LineItems: OrderProductViewLineItemSchema.array(),
});

export const OrderProductViewContributionSchema = EntitySchema.extend({
	Amount: CurrencyAmountSchema,
	Fund: EntitySummarySchema,
	Notes: z.string(),
	CustomDataItems: CustomDataItemSchema.array(),
});

export const OrderProductViewMembershipSchema = EntitySchema.extend({
	Amount: CurrencyAmountSchema,
	Fund: EntitySummarySchema,
	MembershipOrganization: EntitySummarySchema,
	MembershipLevel: EntitySummarySchema,
	CustomDataItems: CustomDataItemSchema.array(),
});

export const OrderProductViewGiftCertificateSchema = z.object({
	PaymentId: ApiIdSchema,
	GiftCertificateNumber: z.string(),
	Amount: CurrencyAmountSchema,
	Notes: z.string(),
	Name: z.string(),
});

export const OrderProductViewProductSchema = z.object({
	ProductClass: EntitySummarySchema,
	Performance: OrderProductViewProductPerformanceSchema,
	Package: OrderProductViewProductPackageSchema,
	Contribution: OrderProductViewContributionSchema,
	Membership: OrderProductViewMembershipSchema,
	GiftCertificate: OrderProductViewGiftCertificateSchema,
	UserDefinedFee: OrderProductViewFeeDetailSchema,
	ProductGrouping: z.string(),
});

export const OrderProductViewSchema = EntitySchema.extend({
	AddressId: ApiIdSchema,
	Appeal: EntitySummarySchema,
	BatchId: ApiIdSchema,
	BookingId: ApiIdSchema,
	BusinessUnit: EntitySummarySchema,
	Category: EntitySummarySchema,
	Constituent: ConstituentDisplaySummarySchema,
	CustomDataItems: CustomDataItemSchema.array(),
	DeliveryMethod: EntitySummarySchema,
	ElectronicAddressId: ApiIdSchema,
	HoldUntilDateTime: DateishSchema,
	Initiator: ConstituentDisplaySummarySchema,
	Messages: PricingRuleMessageSchema,
	ModeOfSale: EntitySummarySchema,
	OrderDateTime: DateishSchema,
	OrderFees: OrderProductViewFeeDetailSchema.array(),
	OrderNotes: z.string(),
	PaymentPlans: OrderProductViewPaymentPlanSchema.array(),
	Payments: OrderProductViewPaymentSchema.array(),
	PhoneId: ApiIdSchema,
	Products: OrderProductViewProductSchema.array(),
	Solicitor: z.string(),
	Source: EntitySummarySchema,
	TotalContributionAmount: z.number(),
	TotalContributionPaidAmount: z.number(),
	TotalDueAmount: z.number(),
	TotalFeeAmount: z.number(),
	TotalFeePaidAmount: z.number(),
	TotalPaidAmount: z.number(),
	TotalPurchaseAmount: z.number(),
	TotalReturnAmount: z.number(),
});

export const CheckoutStatusSchema = z.object({
	Status: z.string(),
	Date: DateishSchema.nullish(),
});

export const SessionLoginInfoSchema = z.object({
	ConstituentId: ApiIdSchema.nullish(),
	OriginalConstituentId: ApiIdSchema.nullish(),
	UserId: ApiIdSchema.or(z.string()).nullish(), // NOTE: String not a numerical ID?
	Status: z.string().nullish(),
	FailedAttempts: IntegerSchema,
	LockedDate: DateishSchema.nullish(),
	ElectronicAddress: z.string().nullish(),
});

export const WebSessionCreateSchema = z.object({
	BusinessUnitId: ApiIdSchema,
	IpAddress: z.string(),
	Organization: z.string(),
	PromotionCode: ApiIdSchema,
});

export const SetConstituentRequestSchema = z.object({
	ConstituentId: ApiIdSchema,
	OverridePrompt: z.boolean(),
});

export const WebConstituentDisplaySummarySchema = EntitySummarySchema;

export const WebSessionSchema = z.object({
	BusinessFacing: z.boolean(),
	CartInfo: CartInfoSchema,
	CheckoutStatus: CheckoutStatusSchema,
	HasLockedSeats: z.boolean(),
	IsGuest: z.boolean(),
	IsLoggedIn: z.boolean(),
	LoginInfo: SessionLoginInfoSchema,
	ModeOfSaleId: ApiIdSchema,
	OriginalModeOfSaleId: ApiIdSchema,
	OrderId: ApiIdSchema,
	SeatsExpired: z.boolean(),
	SourceId: ApiIdSchema,
});

export const AppealSummarySchema = EntitySummarySchema;

export const SourceSummarySchema = EntitySummarySchema.extend({
	Appeal: AppealSummarySchema,
});

export const WebPromoCodeSchema = z.object({
	ModeOfSaleId: ApiIdSchema,
	OverrideRankIndicator: z.string(),
	PromoCode: z.string(),
	PromotionDate: DateishSchema,
	Source: SourceSummarySchema,
	Text1: z.string(),
	Text2: z.string(),
	Text3: z.string(),
	Text4: z.string(),
	Text5: z.string(),
	Text6: z.string(),
});

export const ZoneSummarySchema = EntitySummarySchema.extend({
	ShortDescription: z.string(),
	Rank: IntegerSchema,
	ZoneMapId: ApiIdSchema,
	ZoneTime: z.string(),
	Abbreviation: z.string(),
	ZoneLegend: z.string(),
	ZoneGroup: ZoneGroupSummarySchema,
});

export const PerformanceZoneAvailabilitySchema = z.object({
	Zone: ZoneSummarySchema,
	SectionSummaries: SectionSummarySchema.array(),
	PerformanceId: ApiIdSchema,
	AvailableCount: IntegerSchema,
	Inactive: z.boolean(),
});

export const PerformanceZoneAvailabilityWithConstituentSchema =
	PerformanceZoneAvailabilitySchema.extend({
		Id: ApiIdSchema,
		ConstituentAvailableCount: IntegerSchema,
	});

export const PriceSummarySchema = z.object({
	EditableMinPrice: CurrencyAmountSchema,
	Enabled: z.boolean(),
	IsBase: z.boolean(),
	IsBest: z.boolean(),
	IsEditable: z.boolean(),
	IsEditableForWeb: z.boolean(),
	LayerTypeId: ApiIdSchema.nullish(),
	MinPrice: CurrencyAmountSchema,
	ModeOfSaleOfferId: ApiIdSchema,
	Offer: z.boolean(),
	PackageId: ApiIdSchema,
	ParentPackageId: ApiIdSchema,
	PerformanceId: ApiIdSchema,
	PerformancePriceTypeId: ApiIdSchema,
	Price: CurrencyAmountSchema,
	PriceTypeId: ApiIdSchema,
	ZoneId: ApiIdSchema,
});

export const PackagePerformanceGroupDetailSchema = z.object({
	AvailableIndicator: z.boolean(),
	DisplayByZone: z.boolean(),
	Facility: z.string(),
	FixedSeatIndicator: z.boolean(),
	FlexIndicator: z.boolean(),
	GroupDescription: z.string(),
	GroupId: ApiIdSchema,
	GroupMaxPerformances: IntegerSchema,
	GroupMinPerformances: IntegerSchema,
	GroupRanking: IntegerSchema,
	Inactive: z.boolean(),
	MaxPerformances: IntegerSchema,
	MinPerformances: IntegerSchema,
	PackageDescription: z.string(),
	PackageId: ApiIdSchema,
	PerformanceCode: z.string(),
	PerformanceDateTime: DateishSchema,
	PerformanceDescription: z.string(),
	PerformanceId: ApiIdSchema,
	SalesNotes: z.string().nullish(),
	SalesNotesReadIndicator: z.boolean(),
	ZoneMapId: ApiIdSchema,
	Zones: PerformanceZoneAvailabilitySchema.array(),
});

export const SeatMapSummarySchema = EntitySummarySchema;

export const FacilitySummarySchema = EntitySummarySchema.extend({
	SeatMap: EntitySummarySchema,
});

export const ControlGroupSummarySchema = TypeSummarySchema;

export const CampaignSchema = TypeSummarySchema.extend({
	AutoRestrictContributionsBeforeStartDate: z.boolean(),
	Type: z.string(),
	CategoryId: ApiIdSchema,
	ControlGroup: ControlGroupSummarySchema,
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	EndDateTime: DateishSchema,
	EventDateTime: DateishSchema.nullish(),
	EventExpense: CurrencyAmountSchema,
	EventMinimumAmount: CurrencyAmountSchema,
	FYear: IntegerSchema.nullish(),
	GiftAmount: CurrencyAmountSchema,
	GoalAmount: CurrencyAmountSchema,
	UpdatedDateTime: DateishSchema,
	UpdatedBy: z.string(),
	MembershipIncludesMatchingGifts: z.string().nullish(),
	MinimumPledgeAmount: CurrencyAmountSchema,
	Notes: z.string().nullish(),
	NumSuccessCust: IntegerSchema.nullish(),
	NumTargetCust: IntegerSchema.nullish(),
	NumTickets: IntegerSchema.nullish(),
	PledgeReceivedAmount: CurrencyAmountSchema,
	PledgeAmount: CurrencyAmountSchema,
	SingleOpenPledgeIndicator: z.string().nullish(),
	StartDateTime: DateishSchema,
	Status: z.string(),
	TotalTicketIncome: CurrencyAmountSchema,
	EditIndicator: z.boolean(),
});

export const ProductionSummarySchema = EntitySummarySchema;

export const SeasonSummarySchema = EntitySummarySchema.extend({
	FYear: IntegerSchema,
	Inactive: z.boolean(),
});

export const ProductionSeasonSummarySchema = EntitySummarySchema.extend({
	Production: ProductionSummarySchema,
	Season: SeasonSummarySchema,
});

export const RankTypeSummarySchema = TypeSummarySchema.extend({
	ControlGroup: ConstituentGroupSummarySchema,
});

export const PerformanceStatusSummarySchema = EntitySummarySchema;

export const TimeSlotSummarySchema = TypeSummarySchema;

export const PerformanceTypeSummarySchema = TypeSummarySchema;

export const PerformanceSchema = EntitySchema.extend({
	AvailSaleIndicator: z.boolean(),
	BestSeatMap: BestSeatMapSummarySchema,
	BudgetAmount: CurrencyAmountSchema,
	Campaign: CampaignSchema,
	Code: z.string(),
	CreatedBy: z.string(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	Date: DateishSchema,
	DefaultEndSaleDateTime: DateishSchema,
	DefaultStartSaleDateTime: DateishSchema,
	Description: z.string(),
	DoorsClose: DateishSchema,
	DoorsOpen: DateishSchema,
	Duration: IntegerSchema.nullish(),
	EditIndicator: z.boolean(),
	Facility: FacilitySummarySchema,
	Id: ApiIdSchema,
	ProductionSeason: ProductionSeasonSummarySchema,
	PublishClientEndDate: DateishSchema,
	PublishClientStartDate: DateishSchema,
	PublishWebApiEndDate: DateishSchema,
	PublishWebApiStartDate: DateishSchema,
	RankType: RankTypeSummarySchema,
	SalesNotes: z.string().nullish(),
	SalesNotesRequired: z.boolean(),
	Season: SeasonSummarySchema,
	ShortName: z.string(),
	Status: PerformanceStatusSummarySchema,
	Text1: z.string().nullish(),
	Text2: z.string().nullish(),
	Text3: z.string().nullish(),
	Text4: z.string().nullish(),
	TimeSlot: TimeSlotSummarySchema,
	TvIndicator: z.boolean().nullish(),
	Type: PerformanceTypeSummarySchema,
	UpdatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
	ZoneMap: ZoneMapSummarySchema,
});

export const PackageTypeSummarySchema = TypeSummarySchema;

export const MembershipOrganizationSummarySchema = EntitySummarySchema.extend({
	EditIndicator: z.boolean(),
});

export const CampaignSummarySchema = EntitySummarySchema.extend({
	FYear: IntegerSchema,
	Inactive: z.boolean(),
	MembershipOrganization: MembershipOrganizationSummarySchema.nullish(),
});

export const TicketDesignSchema = EntitySummarySchema;

export const _PackageBase = EntitySummarySchema.extend({
	BestSeatMap: BestSeatMapSummarySchema.nullish(),
	Campaign: CampaignSummarySchema,
	Code: z.string(),
	ControlGroup: ControlGroupSummarySchema,
	Facility: FacilitySummarySchema.nullish(),
	MaximumPerformances: IntegerSchema,
	MinimumPerformances: IntegerSchema,
	PackageDateTime: DateishSchema,
	RankType: RankTypeSummarySchema,
	Season: SeasonSummarySchema,
	Text1: z.string().nullish(),
	Text2: z.string().nullish(),
	Text3: z.string().nullish(),
	Text4: z.string().nullish(),
	TicketDesign: TicketDesignSchema,
	Type: PackageTypeSummarySchema,
	ZoneMap: ZoneMapSummarySchema.nullish(),
});

export const PackageSchema = _PackageBase.extend({
	FixedSeatIndicator: z.boolean(),
	FlexIndicator: z.boolean(),
	SubPackageIndicator: z.boolean(),
	SubPackageOrderId: ApiIdSchema.nullish(),
	SuperPackageIndicator: z.boolean(),
	SuperPackageId: ApiIdSchema,
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
	UpdatedBy: z.string(),
	EditIndicator: z.boolean(),
});

export const PerformanceGroupPerformanceDetailSchema = z.object({
	AvailableIndicator: z.boolean(),
	DisplayByZone: z.boolean(),
	Facility: z.string(),
	Inactive: z.boolean(),
	PerformanceDateTime: DateishSchema,
	PerformanceDescription: z.string(),
	PerformanceCode: z.string(),
	PerformanceId: ApiIdSchema,
	SalesNotes: z.string().nullish(),
	ZoneMapId: ApiIdSchema,
	Zones: PerformanceZoneAvailabilitySchema.array(),
});

export const PerformanceGroupDetailSchema = EntitySchema.extend({
	MaximumPerformances: IntegerSchema,
	MinimumPerformances: IntegerSchema,
	PerformanceGroupPerformanceDetails:
		PerformanceGroupPerformanceDetailSchema.array(),
	Rank: IntegerSchema,
});

export const SubPackageDetailSchema = EntitySummarySchema.extend({
	BestSeatMap: BestSeatMapSummarySchema,
	Code: z.string(),
	ControlGroup: ControlGroupSummarySchema,
	Facility: FacilitySummarySchema,
	IsFixedSeatPackage: z.boolean(),
	IsFlexSeatPackage: z.boolean(),
	MaximumPerformances: IntegerSchema,
	MinimumPerformances: IntegerSchema,
	PerformanceGroupDetails: PerformanceGroupDetailSchema.array(),
	Season: SeasonSummarySchema,
	Type: PackageTypeSummarySchema,
	ZoneMap: ZoneMapSummarySchema,
});

export const PackageDetailSchema = _PackageBase.extend({
	IsFixedSeatPackage: z.boolean(),
	IsFlexSeatPackage: z.boolean(),
	IsSuperPackage: z.boolean(),
	PerformanceGroupDetails: PerformanceGroupDetailSchema.array(),
	SubPackages: SubPackageDetailSchema.array(),
});

export const WebLoginSchema = EntitySchema.extend({
	Constituent: EntitySchema,
	ConstituentUpdateDate: DateishSchema,
	CreatedBy: z.string(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	EditIndicator: z.boolean(),
	Email: EntitySchema,
	FailedAttempts: IntegerSchema,
	Inactive: z.boolean(),
	LastLoginDate: DateishSchema,
	LockedDate: DateishSchema,
	Login: z.string(),
	LoginType: LoginTypeSummarySchema,
	PrimaryIndicator: z.boolean(),
	TemporaryIndicator: z.boolean(),
	UpdatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
});

export const ModeOfSaleCategorySummarySchema = TypeSummarySchema.extend({
	ShortDescription: z.string(),
	Sequence: IntegerSchema,
});

export const ModeOfSaleSummarySchema = TypeSummarySchema.extend({
	Category: ModeOfSaleCategorySummarySchema,
});

export const PriceTypeSummarySchema = TypeSummarySchema.extend({
	AliasDescription: z.string(),
	DefaultTicketDesign: EntitySchema,
	ShortDescription: z.string(),
});

export const ModeOfSalePriceTypeSchema = EntitySchema.extend({
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	DefaultIndicator: z.boolean(),
	UpdatedDateTime: DateishSchema,
	UpdatedBy: z.string(),
	ModeOfSale: ModeOfSaleSummarySchema,
	PriceType: PriceTypeSummarySchema,
});

export const PremiereSummarySchema = EntitySummarySchema;

export const ProductionSeasonSchema = EntitySummarySchema.extend({
	Premiere: PremiereSummarySchema.nullish(),
	Production: ProductionSummarySchema,
	Season: SeasonSummarySchema,
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
	UpdatedBy: z.string(),
	Fulltext: z.string().nullish(),
	FulltextCompleteDateTime: DateishSchema.nullish(),
	FulltextRequestDateTime: DateishSchema,
	ShortName: z.string().nullish(),
	Text1: z.string().nullish(),
	Text2: z.string().nullish(),
	Text3: z.string().nullish(),
	Text4: z.string().nullish(),
	InventoryType: z.string(),
	FirstPerformanceDate: DateishSchema,
	LastPerformanceDate: DateishSchema,
});

export const CountrySchema = EntitySummarySchema.extend({
	CreatedBy: z.string(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	DecimalSeparator: z.string(),
	Inactive: z.boolean(),
	IsoAlpha3Code: z.string(),
	PhoneCode: z.string(),
	PhoneEdit: z.string(),
	PhoneMask: z.string(),
	PhoneValidLengths: z.string(),
	ReqCity: z.boolean(),
	ReqPcode: z.boolean(),
	ShortDesc: z.string(),
	UpdatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
	UseAvs: z.string(),
	UseStateField: z.string(),
	ZipEdit: z.string(),
	ZipMask: z.string(),
	ZipValidLengths: z.string(),
});

export const StateSchema = TypeSummarySchema.extend({
	Country: CountrySummarySchema,
	CreatedBy: z.string(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	StateCode: z.string(),
	UpdatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
});

export const GiftCertificateInfoSchema = z.object({
	GiftCertificateNumber: CartGiftCertificateSchema,
	OriginalAmount: CurrencyAmountSchema,
	UsedAmount: CurrencyAmountSchema,
	Balance: CurrencyAmountSchema,
	OriginalDate: DateishSchema,
	PrintCount: IntegerSchema,
	LastPrintDate: DateishSchema,
	BatchId: ApiIdSchema,
	SessionKey: WebSessionKeySchema,
	RecipientName: z.string(),
	Notes: z.string(),
});

export const PriceTypeGroupSummarySchema = EntitySummarySchema;

export const PriceTypeCategorySummarySchema = EntitySummarySchema;

export const PriceTypeSchema = TypeSummarySchema.extend({
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	AliasDescription: z.string(),
	EditableIndicator: z.boolean(),
	UpdatedDateTime: DateishSchema,
	UpdatedBy: z.string(),
	PriceTypeGroup: PriceTypeGroupSummarySchema,
	EditIndicator: z.boolean(),
	ReasonIndicator: z.boolean(),
	ShortDescription: z.string(),
	DefaultTicketDesign: TicketDesignSchema,
	PriceTypeCategory: PriceTypeCategorySummarySchema,
});

export const PriceTypeDetailSchema = z.object({
	PriceTypeId: ApiIdSchema,
	AliasDescription: z.string(),
	PerformanceId: ApiIdSchema,
	PackageId: ApiIdSchema,
	SuperPackageId: ApiIdSchema,
	Description: z.string(),
	ReasonIndicator: z.boolean(),
	ShortDescription: z.string(),
	DefaultIndicator: z.boolean(),
	IsBest: z.boolean(),
	Offer: z.boolean(),
	Rank: IntegerSchema,
	MaxSeats: IntegerSchema,
	Terms: z.string(),
	Zones: ZoneSummarySchema.array(),
});

export const PerformancePriceSchema = EntitySchema.extend({
	EffectiveDateTime: DateishSchema,
	EditIndicator: z.boolean(),
	MinPrice: CurrencyAmountSchema,
	PerformanceId: ApiIdSchema,
	PerformancePriceTypeId: ApiIdSchema,
	Price: CurrencyAmountSchema,
	Enabled: z.boolean(),
	HasEvents: z.boolean(),
	IsPriceBasedOnCurrentEvent: z.boolean(),
	ZoneId: ApiIdSchema,
});

export const PerformancePriceTypeSchema = EntitySchema.extend({
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	DesignationCodeId: ApiIdSchema,
	EffectiveDateTime: DateishSchema,
	ResaleAccountId: ApiIdSchema,
	IsWithinDateRange: z.boolean(),
	GlAccountId: ApiIdSchema,
	StartDateTime: DateishSchema,
	EndDateTime: DateishSchema,
	UpdatedDateTime: DateishSchema,
	UpdatedBy: z.string(),
	PerformanceId: ApiIdSchema,
	PerformancePriceLayerId: ApiIdSchema,
	PriceTypeId: ApiIdSchema,
	TicketDesignId: ApiIdSchema,
	BaseIndicator: z.boolean(),
	PerformancePrices: PerformancePriceSchema.array(),
});

export const SeatMapSchema = EntitySummarySchema.extend({
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
	UpdatedBy: z.string(),
});

export const TheaterSummarySchema = EntitySummarySchema;

export const FacilitySchema = EntitySummarySchema.extend({
	DefaultBestSeatMapId: ApiIdSchema,
	DefaultZoneMapId: ApiIdSchema,
	SeatMap: SeatMapSchema,
	Theater: TheaterSummarySchema,
	ControlGroup: ControlGroupSummarySchema,
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
	UpdatedBy: z.string(),
});

export const FeeCategorySummarySchema = EntitySummarySchema.extend({
	Inactive: z.boolean(),
});

export const DesignationCodeSummarySchema = EntitySummarySchema.extend({
	Inactive: z.boolean(),
});

export const FeeSummarySchema = EntitySummarySchema.extend({
	FeeAmount: CurrencyAmountSchema,
	Inactive: z.boolean(),
	Season: SeasonSummarySchema,
	Category: FeeCategorySummarySchema,
	UserDefinedIndicator: z.boolean(),
	EditIndicator: z.boolean(),
});

export const FeeSchema = FeeSummarySchema.extend({
	AllowNegativePercent: z.boolean(),
	BusinessUnitId: ApiIdSchema,
	Campaign: CampaignSummarySchema,
	DesignationCode: DesignationCodeSummarySchema,
	UserEditIndicator: z.boolean(),
	EndAmount: CurrencyAmountSchema,
	ExchangeFeeInd: z.boolean(),
	FeeFrequency: z.string(),
	GlAccount: z.string(),
	FiscalYear: z.number(),
	NeverDelete: z.boolean(),
	PercentIndicator: z.boolean(),
	PercentRound: z.number(),
	StartAmount: CurrencyAmountSchema,
	Text1: z.string(),
	Text2: z.string(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
	UpdatedBy: z.string(),
});

export const AccountTypeSummarySchema = TypeSummarySchema.extend({
	CardPrefix: z.string(),
	CardtypeIndicator: z.string(),
	EditMask: z.string(),
});

export const PaymentMethodGroupSummarySchema = TypeSummarySchema.extend({
	MerchantId: z.string(),
});

export const PaymentTypeSummarySchema = EntitySummarySchema;

export const PaymentMethodSchema = z.object({
	AccountType: AccountTypeSummarySchema,
	AuthIndicator: z.boolean(),
	BusinessUnitId: ApiIdSchema,
	CanRefund: z.boolean(),
	ControlGroup: ControlGroupSummarySchema,
	CreatedBy: z.string(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CurrencyTypeId: ApiIdSchema,
	DefaultIndicator: z.boolean(),
	Description: z.string(),
	GiftAidIndicator: z.boolean(),
	GlAccountId: z.string(),
	Id: ApiIdSchema,
	Inactive: z.boolean(),
	Income: z.boolean(),
	MerchantId: z.string(),
	MerchantIdForSwipe: z.string(),
	NoCopiesOnAuth: IntegerSchema,
	NoCopiesOnSave: IntegerSchema,
	OpenCashDrawer: z.boolean(),
	PaymentMethodGroup: PaymentMethodGroupSummarySchema,
	PaymentType: PaymentTypeSummarySchema,
	ReceiptFormatId: ApiIdSchema,
	RequireCheckIndicator: z.boolean(),
	RequireCvv: z.boolean(),
	RequirePostalCode: z.string(),
	ShortDesc: z.string(),
	StoreTenderedAmount: z.boolean(),
	UpdatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
	UseWithCardReader: z.boolean(),
});

export const DependencySchema = z.object({
	Success: z.boolean(),
	Name: z.string(),
	Description: z.string(),
});

export const StatusSchema = z.object({
	Dependencies: DependencySchema.array(),
	Success: z.boolean(),
	TimeStamp: DateishSchema,
	TimeZone: z.string(),
});

export const SeatServerStatusSchema = z.object({
	IsListening: z.boolean(),
	ConnectionsCount: IntegerSchema,
});

export const KeywordCategorySummarySchema = TypeSummarySchema;

export const KeywordSummarySchema = EntitySummarySchema.extend({
	Category: KeywordCategorySummarySchema,
	CustomId: IntegerSchema,
	DataType: z.string(),
	DetailColumn: z.string(),
	DetailTable: z.string(),
	EditMask: z.string(),
	ExtendedDescription: z.string(),
	HelpText: z.string(),
	KeywordUse: z.string(),
	MultipleValue: z.boolean(),
	ParentTable: z.string(),
	PrimaryGroupDefault: z.string(),
	ReferenceTable: z.string(),
});

export const KeywordSchema = KeywordSummarySchema.extend({
	ConstituentType: IntegerSchema,
	ControlGroup: ControlGroupSummarySchema,
	CreatedBy: z.string(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CustomDefaultValue: z.string(),
	CustomLimit: IntegerSchema,
	CustomRequired: z.boolean(),
	EditIndicator: z.boolean(),
	FrequentUpdateDate: DateishSchema,
	KeyColumn: z.string(),
	MultipleValue: z.boolean(),
	ParentKeyColumn: z.string(),
	ReferenceDescriptionColumn: z.string(),
	ReferenceIdColumn: z.string(),
	ReferenceSort: z.string(),
	ReferenceWhere: z.string(),
	SortOrder: IntegerSchema,
	UpdatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
	UseForSearch: z.boolean(),
	ValuesCodedIndicator: z.boolean(),
});

export const SalutationTypeSchema = TypeSummarySchema.extend({
	ControlGroup: ControlGroupSummarySchema,
	CreatedBy: z.string(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	UpdatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
});

export const AliasTypeSchema = TypeSummarySchema.extend({
	ControlGroup: ControlGroupSummarySchema,
	CreatedBy: z.string(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	UpdatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
});

export const DeliveryMethodSchema = TypeSummarySchema.extend({
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
	UpdatedBy: z.string(),
	PrintAtHome: z.boolean(),
	RequireAddress: z.boolean(),
});

export const VersionSchema = z.object({
	Major: IntegerSchema,
	Minor: IntegerSchema,
	Build: IntegerSchema,
	Revision: IntegerSchema,
	MajorRevision: IntegerSchema,
	MinorRevision: IntegerSchema,
});

export const HttpContentSchema = z.object({
	Headers: z.any().array(),
});

export const HttpMethodSchema = z.object({
	Method: z.string(),
});

export const HttpRequestMessageSchema = z.object({
	Version: VersionSchema,
	Content: z.any(),
	Method: HttpMethodSchema,
	RequestUri: z.string(),
	Headers: z.any().array(),
	Properties: z.record(z.string(), z.any()),
});

export const HttpResponseMessageSchema = z.object({
	Content: z.any(),
	Headers: z.any().array(),
	IsSuccessStatusCode: z.boolean(),
	ReasonPhrase: z.string(),
	RequestMessage: HttpRequestMessageSchema,
	StatusCode: IntegerSchema,
	Version: VersionSchema,
});

export const ListCategorySummarySchema = TypeSummarySchema;

export const ListSummarySchema = TypeSummarySchema.extend({
	Category: ListCategorySummarySchema,
	ConstituentCount: IntegerSchema,
	CreatedBy: z.string(),
	LastUsedDateTime: DateishSchema,
	IsDynamic: z.boolean(),
	EditMode: z.string(),
});

export const ConstituentSummarySchema = EntitySchema.extend({
	DisplayName: z.string(),
	FirstName: z.string(),
	MiddleName: z.string(),
	LastName: z.string(),
	Street1: z.string(),
	Street2: z.string().optional(),
	Street3: z.string().optional(),
	City: z.string().optional(),
	State: z.string(),
	PostalCode: z.string(),
	Country: z.string(),
	FullNameAlias: z.string(),
	ConstituentType: z.string(),
	TypeDescription: z.string(),
	ConstituentGroup: z.string(),
	A12Indicator: z.string(),
	ForSort: z.string(),
	Inactive: z.string(),
	Prefix: z.string(),
	Suffix: z.string(),
	NumberOfAffiliates: IntegerSchema,
	AffiliationId: IntegerSchema,
	ResultGrouping: IntegerSchema,
	Totals: IntegerSchema,
	IsPrimaryAffiliation: z.boolean(),
	IsSearchMatch: z.boolean(),
	GroupSort: z.string(),
	IsPotentialDuplicate: z.boolean(),
});

export const SearchTokenSchema = z.object({
	Key: z.string(),
	Value: z.string(),
});

export const SearchMetadataSchema = z.object({
	SearchTokens: SearchTokenSchema.array(),
	Total: IntegerSchema,
});

export const ConstituentSearchResponseSchema = z.object({
	ConstituentSummaries: ConstituentSummarySchema.array(),
	SearchMetadata: SearchMetadataSchema,
});

export const CartPropertiesSchema = z.object({
	ModeOfSaleId: ApiIdSchema,
	SourceId: ApiIdSchema,
	Solicitor: z.string(),
	CategoryId: ApiIdSchema,
	ChannelId: ApiIdSchema,
	HoldUntilDateTime: DateishSchema,
	Notes: z.string(),
	InitiatorId: ApiIdSchema,
	AddressId: ApiIdSchema,
	PhoneId: ApiIdSchema,
	ElectronicAddressId: ApiIdSchema,
	DeliveryMethodId: ApiIdSchema,
	OrderDateTime: DateishSchema,
});

export const ReserveTicketsResponseSchema = z.object({
	LineItemId: ApiIdSchema,
	SeatsReserved: IntegerSchema,
});

export const AddContributionResponseSchema = z.object({
	LineItemId: ApiIdSchema,
});

export const AddOnAccountResponseSchema = z.object({
	PaymentId: ApiIdSchema,
});

export const AddUpdateFeeResponseSchema = z.object({
	ItemFeeId: ApiIdSchema,
});

export const AddPaymentResponseSchema = z.object({
	PaymentId: ApiIdSchema,
});

export const CartProductResponseSchema = EntitySchema.extend({
	ProductTypeId: ApiIdSchema,
	ParentId: ApiIdSchema,
	ItemCount: IntegerSchema,
});

export const AddNFSPackagePerformanceItemResponseSchema = z.object({
	LineItemId: ApiIdSchema,
	NFSPackageLineItemId: ApiIdSchema,
	SeatsReserved: IntegerSchema,
});

export const GiftCertificateRedemptionRequestSchema = z.object({
	Number: z.string(),
	BatchId: ApiIdSchema,
	LockForBatch: z.boolean(),
	PaymentMethodId: ApiIdSchema,
});

const OwnerType = {
	Performance: 0,
	ProductionSeason: 1,
	Production: 2,
	Title: 3,
	Package: 4,
};

export const OwnerTypeSchema = z.nativeEnum(OwnerType);

export const OwnerSchema = z.object({
	ElementId: ApiIdSchema,
	Type: OwnerTypeSchema,
});

export const KeywordResultKeywordSchema = EntitySummarySchema.extend({
	Owner: OwnerSchema,
	Category: EntitySummarySchema,
});

export const KeywordResultSchema = z.object({
	RequestedOwner: OwnerSchema,
	Keywords: KeywordResultKeywordSchema.array(),
});

export const WebContentTypeSummarySchema = TypeSummarySchema;

export const WebContentSchema = EntitySchema.extend({
	Owner: OwnerSchema,
	Type: WebContentTypeSummarySchema,
	Value: z.string(),
});

export const WebContentResultSchema = z.object({
	RequestedOwner: OwnerSchema,
	WebContents: WebContentSchema.array(),
});

export const OrganizationSummarySchema = EntitySummarySchema;

export const DivisionSchema = EntitySummarySchema.extend({
	Name: z.string(),
	Organization: OrganizationSummarySchema,
});

export const UserGroupSummarySchema = EntitySummarySchema.extend({
	Name: z.string(),
	IsAdmin: z.boolean(),
	AllowApp: z.boolean(),
	AllowTablet: z.boolean(),
	AllowOnTheGo: z.boolean(),
	AllowAnalytics: z.boolean(),
	Division: DivisionSchema,
});

export const UserSchema = EntitySchema.extend({
	FirstName: z.string(),
	LastName: z.string(),
	EmailAddress: z.string(),
	Inactive: z.boolean(),
	WorkerConstituentId: ApiIdSchema,
	Locked: z.boolean(),
	ActiveDirectoryUserName: z.string(),
	LoginCount: IntegerSchema,
	MaxLogins: IntegerSchema,
});

export const AuthenticationResponseSchema = z.object({
	IsAuthenticated: z.boolean(),
	UserGroup: UserGroupSummarySchema,
	User: UserSchema,
	MustChangePassword: z.boolean(),
	MachineLocation: z.string(),
	CurrentLoginCount: IntegerSchema,
	Message: z.string(),
});

export const ActivityCategorySummarySchema = TypeSummarySchema;

export const ContactTypeSummarySchema = TypeSummarySchema;

export const OriginSummarySchema = TypeSummarySchema;

export const ActivityTypeSummarySchema = TypeSummarySchema.extend({
	Category: ActivityCategorySummarySchema,
});

export const ActivityTypeSchema = EntitySchema.extend({
	Category: ActivityCategorySummarySchema,
	ControlGroup: ControlGroupSummarySchema,
	CreatedBy: z.string(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	Description: z.string(),
	Inactive: z.boolean(),
	PerfIndicator: z.string(),
	UpdatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
});

export const IssueSchema = EntitySchema.extend({
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	CreatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
	UpdatedBy: z.string(),
	ActivityType: ActivityTypeSummarySchema,
	ContactType: ContactTypeSummarySchema,
	Constituent: EntitySchema,
	IssueDate: DateishSchema,
	Notes: z.string(),
	Origin: OriginSummarySchema,
	PerformanceId: IntegerSchema,
	PackageId: IntegerSchema,
	UrgentIndicator: z.boolean(),
	ResolvedIndicator: z.boolean(),
	HasReminder: z.boolean(),
	EditIndicator: z.boolean(),
});

export const ActionTypeSummarySchema = TypeSummarySchema;

export const IssueActionSchema = EntitySchema.extend({
	ActionDate: DateishSchema,
	ActionType: ActionTypeSummarySchema,
	Constituent: EntitySchema,
	CreatedBy: z.string(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	EditIndicator: z.boolean(),
	Id: IntegerSchema,
	Issue: EntitySchema,
	LetterPrintedDate: DateishSchema,
	Notes: z.string(),
	Resolved: z.boolean(),
	UpdatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
});

export const DiscountTypeSchema = EntitySchema.extend({
	Amount: CurrencyAmountSchema,
	CreatedBy: z.string(),
	CreatedDateTime: DateishSchema,
	CreateLocation: z.string(),
	Description: z.string(),
	Inactive: z.boolean(),
	PercentIndicator: z.boolean(),
	RespectMinimumPrice: z.boolean(),
	ShortDescription: z.string(),
	UpdatedBy: z.string(),
	UpdatedDateTime: DateishSchema,
});

export const TransactionHistorySchema = z.object({
	TransactionId: ApiIdSchema,
	TransactionDateTime: DateishSchema,
	TransactionTypeDescription: z.string(),
	ReferenceId: ApiIdSchema,
	TransactionAmount: CurrencyAmountSchema,
	BatchId: ApiIdSchema,
	CampaignDescription: z.string(),
	AppealDescription: z.string(),
	Source: z.string(),
	MediaTypeDescription: z.string(),
	FundDescription: z.string(),
	AdjustedReasonDescription: z.string(),
	CreatedDateTime: DateishSchema,
	CreatedBy: z.string(),
	CreateLocation: z.string(),
	ConstituentId: ApiIdSchema,
	PostId: ApiIdSchema,
});

export const TransactionHistoryResponseSchema = z.object({
	TotalCount: IntegerSchema,
	Page: IntegerSchema,
	PageSize: IntegerSchema,
	Items: TransactionHistorySchema.array(),
});

export const PerformanceSearchResponseSchema = z.object({
	PerformanceId: ApiIdSchema,
	PerformanceCode: z.string(),
	PerformanceDate: DateishSchema,
	Facility: EntitySummarySchema,
	PerformanceDescription: z.string(),
	PerformanceShortName: z.string(),
	ProductionSeason: EntitySummarySchema,
	BusinessUnitId: ApiIdSchema,
	ZoneMap: EntitySummarySchema,
	AvailSaleIndicator: z.string(),
	ConstituentInterestWeight: IntegerSchema,
	Season: SeasonSummarySchema,
	PublishClientStartDate: DateishSchema,
	PublishClientEndDate: DateishSchema,
	PublishWebApiStartDate: DateishSchema,
	PublishWebApiEndDate: DateishSchema,
	DoorsOpen: DateishSchema,
	DoorsClose: DateishSchema,
	Duration: IntegerSchema,
	SalesNotes: z.string(),
	SalesNotesRequired: z.boolean(),
	Text1: z.string(),
	Text2: z.string(),
	Text3: z.string(),
	Text4: z.string(),
	RankType: EntitySummarySchema,
	PerformanceType: PerformanceTypeSummarySchema,
	PerformanceStatus: EntitySummarySchema,
	TimeSlot: EntitySummarySchema,
	ModeOfSale: EntitySummarySchema,
	ModeOfSaleStartDate: DateishSchema,
	ModeOfSaleEndDate: DateishSchema,
	IsRestricted: z.boolean(),
	ValidCountries: z.string(),
});

export const PackageSearchResponseSchema = z.object({
	PackageId: ApiIdSchema,
	PackageCode: z.string(),
	PackageDescription: z.string(),
	PackageType: PackageTypeSummarySchema,
	PackageDateTime: DateishSchema,
	Facility: EntitySummarySchema,
	FirstPerformanceDateTime: DateishSchema,
	LastPerformanceDateTime: DateishSchema,
	FixedSeatIndicator: z.boolean(),
	FlexIndicator: z.boolean(),
	MinimumPerformances: IntegerSchema,
	MaximumPerformances: IntegerSchema,
	RankType: EntitySummarySchema,
	Season: SeasonSummarySchema,
	SuperPackageIndicator: z.boolean(),
	Text1: z.string(),
	Text2: z.string(),
	Text3: z.string(),
	Text4: z.string(),
	ConstituentInterestWeight: IntegerSchema,
	ZoneMap: EntitySummarySchema,
	ModeOfSale: EntitySummarySchema,
	ModeOfSaleStartDate: DateishSchema,
	ModeOfSaleEndDate: DateishSchema,
});

export const SeatSchema = EntitySchema.extend({
	AisleIndicator: z.string(),
	AllocationId: ApiIdSchema,
	DisplayLetter: z.string(),
	HasStairs: z.boolean(),
	HoldCodeId: ApiIdSchema,
	IsSeat: z.boolean(),
	LogicalSeatNumber: z.number(),
	LogicalSeatRow: z.number(),
	ScreenId: ApiIdSchema,
	SeatNumber: z.string(),
	SeatRow: z.string(),
	SeatStatusId: ApiIdSchema,
	SeatTypeId: ApiIdSchema,
	SectionId: ApiIdSchema,
	XPosition: z.number(),
	YPosition: z.number(),
	ZoneId: ApiIdSchema,
});

export const PaymentComponentSchema = z.object({
	Checksum: z.string(),
	Payload: z.string(),
	Url: UrlSchema,
});
