"use client";

import { RefObject, useEffect } from "react";

import { z } from "zod";

import { useSearchParams } from "next/navigation";

import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";

import Cookies from "js-cookie";

import * as Sentry from "@sentry/browser";

import { betterFetch, FetchError } from "../../betterFetch";
import { TBetterError } from "../../errors";
import {
	TESSITURA_DEFAULT_MODE_OF_SALE_ID,
	TESSITURA_SOURCE_ID_COOKIE_NAME,
	TESSITURA_SOURCE_ID_EXPIRY_DAYS,
} from "../../tessituraConstants";
import { isTruthy } from "../../utils";
import {
	GA4EcommerceItem,
	trackGA4EcommerceAddToCart,
	trackGA4EcommercePurchase,
	trackGA4EcommerceRemoveFromCart,
} from "../tracking";

import { useSiteErrorActions } from "@/lib/stores/siteError";
import { arrayToListsObjectWithFunction } from "@/lib/utils";
import { calculateCartExpirationStats } from "../../tessitura";
import {
	ApiTessituraCartAddResponseSchema,
	ApiTessituraCartGiftCardRequestSchema,
	ApiTessituraCartGiftCardResponseSchema,
	ApiTessituraCartPromoCodeRequestSchema,
	ApiTessituraCartPromoCodeResponseSchema,
	ApiTessituraCartRemoveRequestSchema,
	ApiTessituraCartRemoveResponseSchema,
	ApiTessituraPaymentComponentRequestSchema,
	ApiTessituraPaymentComponentResponseSchema,
	ApiTessituraPaymentLoginRequestSchema,
	ApiTessituraPaymentZeroCheckoutResponseSchema,
	ApiTessituraStatesResponseSchema,
	MonaCartAndWebSessionSchema,
	MonaCartSchema,
	PackageConsolidatedWithTicketOptionsSchema,
	PackageToAddToCartSchema,
	PaymentStepSchema,
	PerformanceToAddToCartSchema,
	PerformanceWithTicketOptionsSchema,
	WebSessionWithExpirationsSchema,
} from "../../tessituraApiSchemas";

/**
 * Hook to save a Tessitura tracking source ID integer from query string
 * parameters – `promo` or `source` – to a browser cookie so it will be
 * provided to API requests.
 *
 * We must support both parameter names because either could be used in a URL,
 * depending on how it was generated and by whom.
 *
 * NOTE: See also `middlewareStoreTessituraSourceIdInCookie()`
 */
export function useStoreTessituraSourceIdInCookie() {
	const searchParams = useSearchParams();

	const sourceId = searchParams.get("promo") || searchParams.get("source");

	useEffect(() => {
		if (sourceId && !Number.isNaN(parseInt(sourceId))) {
			const rootDomain = window.location.host.split(":")[0];

			Cookies.set(TESSITURA_SOURCE_ID_COOKIE_NAME, sourceId, {
				domain: rootDomain,
				// `expires`: number of *days* from time of creation or a Date instance
				expires: TESSITURA_SOURCE_ID_EXPIRY_DAYS,
			});
		}
	}, [sourceId]);
}

async function getCartAndWebSessionWithExpirations({
	sessionKeyForCompletedCart,
}: {
	sessionKeyForCompletedCart?: string;
}) {
	const [monaCart, webSessionWithExpirations] = await Promise.all([
		betterFetch("/api/tessitura/cart", {
			params: {
				sessionKey: sessionKeyForCompletedCart,
				// `saved` param must be undefined for false, not a falsey literal
				saved: sessionKeyForCompletedCart ? "true" : undefined,
			},
		}),
		betterFetch("/api/tessitura/session"),
	]);

	return MonaCartAndWebSessionSchema.parse({
		monaCart,
		webSessionWithExpirations,
	});
}

export function useCartAndWebSessionWithExpirationsQuery(
	{ sessionKeyForCompletedCart }: { sessionKeyForCompletedCart?: string } = {},
	options: UseQueryOptions<
		Awaited<ReturnType<typeof getCartAndWebSessionWithExpirations>>
	> = {},
) {
	return useQuery<z.infer<typeof MonaCartAndWebSessionSchema>, Error>(
		[`cart-and-web-session-with-expirations-${sessionKeyForCompletedCart}`],
		async () =>
			getCartAndWebSessionWithExpirations({
				sessionKeyForCompletedCart,
			}),
		{
			// Retry N times after a failure
			retry: 3,

			// `staleTime` sets how long in milliseconds until fetched data is
			// considered stale, after which time an action like query re-mount or
			// window focus will trigger a refetch
			staleTime: 60000,

			// `refetchInterval` sets the query to automatically refetch every N
			// milliseconds. Refetch will not happen if browser window is in the
			// background, unless `refetchIntervalInBackground` is set to true.
			refetchInterval: 60000,

			// Overrides
			...options,
		},
	);
}

export function useAddPerformanceToCartMutation({
	ModeOfSaleId = TESSITURA_DEFAULT_MODE_OF_SALE_ID,
	permitNonContiguousSeats,
	priceDiscountPercentage = 0,
	item_category = "performance",
	onSuccess,
}: {
	ModeOfSaleId?: number;
	permitNonContiguousSeats?: boolean;
	priceDiscountPercentage?: number;
	item_category?: string;
	onSuccess?: (data: z.infer<typeof ApiTessituraCartAddResponseSchema>) => void;
} = {}) {
	const cartAndWebSessionWithExpirationsQuery =
		useCartAndWebSessionWithExpirationsQuery();

	const { showSiteError } = useSiteErrorActions();

	function buildAddPerformanceToCartData({
		selectedPerformanceWithTicketOptions,
		ticketQuantityByPriceTypeAndZoneIdsSlug,
	}: {
		selectedPerformanceWithTicketOptions: z.infer<
			typeof PerformanceWithTicketOptionsSchema
		>;
		ticketQuantityByPriceTypeAndZoneIdsSlug: Record<string, number>;
	}): Array<z.infer<typeof PerformanceToAddToCartSchema>> {
		const result: Array<z.infer<typeof PerformanceToAddToCartSchema>> = [];

		for (const priceTypeAndZoneIdsSlug of Object.keys(
			ticketQuantityByPriceTypeAndZoneIdsSlug,
		)) {
			const quantity =
				ticketQuantityByPriceTypeAndZoneIdsSlug[priceTypeAndZoneIdsSlug];

			const [priceTypeId, zoneId] = priceTypeAndZoneIdsSlug.split("-");

			const ticketOption =
				selectedPerformanceWithTicketOptions.ticketOptions.find(
					(ticketOption) =>
						String(ticketOption.priceTypeId) === priceTypeId &&
						String(ticketOption.zoneId) === zoneId,
				);

			if (
				ticketOption?.item?.type === "performance" &&
				// Don't try to add price types with zero quantity
				quantity > 0
			) {
				result.push({
					performanceId: ticketOption.item.performanceId,
					title: ticketOption.description,
					priceTypeId: ticketOption.priceTypeId,
					priceTypeDescription:
						ticketOption.zoneDescription +
						" " +
						(ticketOption.priceTypeAliasDescription ||
							ticketOption.priceTypeDescription),
					zoneId: ticketOption.zoneId,
					price: ticketOption.price,
					quantity,
				});
			}
		}

		return result;
	}

	const addPerformanceToCartMutation = useMutation<
		z.infer<typeof ApiTessituraCartAddResponseSchema>,
		TBetterError,
		Array<z.infer<typeof PerformanceToAddToCartSchema>>,
		unknown
	>(
		async (
			performancesToAddToCart: Array<
				z.infer<typeof PerformanceToAddToCartSchema>
			>,
		) => {
			const response = await betterFetch("/api/tessitura/cart/add", {
				body: {
					ModeOfSaleId,
					permitNonContiguousSeats,
					performances: performancesToAddToCart,
					packages: [],
				},
			});

			return ApiTessituraCartAddResponseSchema.parse(response);
		},
		{
			onSuccess: (performanceAddToCartResult) => {
				// Trigger refresh of web cart for the just-added performances
				cartAndWebSessionWithExpirationsQuery.refetch();

				onSuccess?.(performanceAddToCartResult);

				try {
					trackGA4EcommerceAddToCart({
						event: "add_to_cart",
						ecommerce: {
							items: performanceAddToCartResult.performancesAdded.map(
								(addedPerformance) => {
									let price = addedPerformance.price;
									if (price && priceDiscountPercentage) {
										price = (price * (100 - priceDiscountPercentage)) / 100;
									}

									return {
										currency: "AUD",
										item_id: String(addedPerformance.performanceId),
										item_name: addedPerformance.title,
										item_brand: "Mona Foma",
										item_category,
										item_variant: addedPerformance.priceTypeDescription,
										price,
										quantity: addedPerformance.quantity,
									};
								},
							),
						},
						...calculateCartExpirationStats(
							cartAndWebSessionWithExpirationsQuery.data
								?.webSessionWithExpirations,
						),
					});
				} catch (error) {
					Sentry.captureException(error, {
						extra: { info: performanceAddToCartResult },
					});
				}
			},
			onError: (error) => {
				showSiteError(error);

				// Trigger refresh of web cart if performances were added despite error
				if (isTruthy(error.info?.data?.added)) {
					cartAndWebSessionWithExpirationsQuery.refetch();
				}
			},
		},
	);

	return { addPerformanceToCartMutation, buildAddPerformanceToCartData };
}

export function useRemovePerformanceFromCartMutation({
	onSuccess,
}: {
	onSuccess?: (
		data: z.infer<typeof ApiTessituraCartRemoveResponseSchema>,
	) => void;
} = {}) {
	const cartAndWebSessionWithExpirationsQuery =
		useCartAndWebSessionWithExpirationsQuery();

	const { showSiteError } = useSiteErrorActions();

	return useMutation<
		z.infer<typeof ApiTessituraCartRemoveResponseSchema>,
		TBetterError,
		z.infer<typeof ApiTessituraCartRemoveRequestSchema>,
		unknown
	>(
		async (data) => {
			const response = await betterFetch("/api/tessitura/cart/remove", {
				body: data,
			});

			return ApiTessituraCartRemoveResponseSchema.parse(response);
		},
		{
			onSuccess: (data) => {
				if (isTruthy(data?.performancesRemoved)) {
					// Trigger refresh of web cart for the just-removed performances
					cartAndWebSessionWithExpirationsQuery.refetch();

					onSuccess?.(data);

					data.performancesRemoved?.forEach((removedPerformance) => {
						try {
							trackGA4EcommerceRemoveFromCart({
								event: "remove_from_cart",
								ecommerce: {
									items: [
										{
											currency: "AUD",
											item_id: String(removedPerformance.performanceId),
											item_name: removedPerformance.title,
											item_brand: "Mona Foma",
											item_category: "Performance",
											item_variant: removedPerformance.priceTypeDescription,
											price: Number(removedPerformance.price),
											quantity: removedPerformance.quantity || 1,
										},
									],
								},
								...calculateCartExpirationStats(
									cartAndWebSessionWithExpirationsQuery.data
										?.webSessionWithExpirations,
								),
							});
						} catch (error) {
							Sentry.captureException(error, {
								extra: { info: data.performancesRemoved },
							});
						}
					});
				}
			},
			onError: showSiteError,
		},
	);
}

export function useAddPackageToCartMutation({
	ModeOfSaleId = TESSITURA_DEFAULT_MODE_OF_SALE_ID,
	permitNonContiguousSeats,
	onSuccess,
}: {
	ModeOfSaleId?: number;
	permitNonContiguousSeats?: boolean;
	onSuccess?: (data: z.infer<typeof ApiTessituraCartAddResponseSchema>) => void;
} = {}) {
	const cartAndWebSessionWithExpirationsQuery =
		useCartAndWebSessionWithExpirationsQuery();

	const { showSiteError } = useSiteErrorActions();

	function buildAddPackageToCartData({
		packageWithTicketOptions,
		ticketQuantityByPriceTypeAndZoneIdsSlug,
	}: {
		packageWithTicketOptions: z.infer<
			typeof PackageConsolidatedWithTicketOptionsSchema
		>;
		ticketQuantityByPriceTypeAndZoneIdsSlug: Record<string, number>;
	}): Array<z.infer<typeof PackageToAddToCartSchema>> {
		const result: Array<z.infer<typeof PackageToAddToCartSchema>> = [];

		for (const priceTypeAndZoneIdsSlug of Object.keys(
			ticketQuantityByPriceTypeAndZoneIdsSlug,
		)) {
			const quantity =
				ticketQuantityByPriceTypeAndZoneIdsSlug[priceTypeAndZoneIdsSlug];

			const [priceTypeId, zoneId] = priceTypeAndZoneIdsSlug.split("-");

			const ticketOption = packageWithTicketOptions.ticketOptions.find(
				(ticketOption) =>
					String(ticketOption.priceTypeId) === priceTypeId &&
					String(ticketOption.zoneId) === zoneId,
			);

			if (
				ticketOption?.item?.type === "package" &&
				// Don't try to add items with zero quantity
				quantity > 0
			) {
				result.push({
					packageId: ticketOption.item.packageId,
					performanceGroupId: ticketOption.item.performanceGroupId,
					performanceIds: ticketOption.item.performanceIds,
					title: ticketOption.description,
					priceTypeId: ticketOption.priceTypeId,
					priceTypeDescription:
						ticketOption.zoneDescription +
						" " +
						(ticketOption.priceTypeAliasDescription ||
							ticketOption.priceTypeDescription),
					zoneId: ticketOption.zoneId,
					price: ticketOption.price,
					quantity,
				});
			}
		}

		return result;
	}

	const addPackageToCartMutation = useMutation<
		z.infer<typeof ApiTessituraCartAddResponseSchema>,
		TBetterError,
		Array<z.infer<typeof PackageToAddToCartSchema>>,
		unknown
	>(
		async (
			packagesToAddToCart: Array<z.infer<typeof PackageToAddToCartSchema>>,
		) => {
			const response = await betterFetch("/api/tessitura/cart/add", {
				body: {
					ModeOfSaleId,
					permitNonContiguousSeats,
					packages: packagesToAddToCart,
					performances: [],
				},
			});

			return ApiTessituraCartAddResponseSchema.parse(response);
		},
		{
			onSuccess: (packageAddToCartResult) => {
				// Trigger refresh of web cart for the just-added performances
				cartAndWebSessionWithExpirationsQuery.refetch();

				onSuccess?.(packageAddToCartResult);

				try {
					trackGA4EcommerceAddToCart({
						event: "add_to_cart",
						ecommerce: {
							items: packageAddToCartResult.packagesAdded.map(
								(addedPackage) => {
									return {
										currency: "AUD",
										item_id: String(addedPackage.packageId),
										item_name: addedPackage.title,
										item_brand: "Mona Foma",
										item_category: "Package",
										item_variant: addedPackage.priceTypeDescription,
										price: Number(addedPackage.price),
										quantity: addedPackage.quantity,
									};
								},
							),
						},
						...calculateCartExpirationStats(
							cartAndWebSessionWithExpirationsQuery.data
								?.webSessionWithExpirations,
						),
					});
				} catch (error) {
					Sentry.captureException(error, {
						extra: { info: packageAddToCartResult },
					});
				}
			},
			onError: (error) => {
				showSiteError(error);

				// Trigger refresh of web cart if performances were added despite error
				if (isTruthy(error.info?.data?.added)) {
					cartAndWebSessionWithExpirationsQuery.refetch();
				}
			},
		},
	);

	return { addPackageToCartMutation, buildAddPackageToCartData };
}

export function useRemovePackageFromCartMutation({
	onSuccess,
}: {
	onSuccess?: (
		data: z.infer<typeof ApiTessituraCartRemoveResponseSchema>,
	) => void;
} = {}) {
	const cartAndWebSessionWithExpirationsQuery =
		useCartAndWebSessionWithExpirationsQuery();

	const { showSiteError } = useSiteErrorActions();

	return useMutation<
		z.infer<typeof ApiTessituraCartRemoveResponseSchema>,
		TBetterError,
		z.infer<typeof ApiTessituraCartRemoveRequestSchema>,
		unknown
	>(
		async (data) => {
			const response = await betterFetch("/api/tessitura/cart/remove", {
				body: data,
			});

			return ApiTessituraCartRemoveResponseSchema.parse(response);
		},
		{
			onSuccess: (data) => {
				if (isTruthy(data?.packagesRemoved)) {
					// Trigger refresh of web cart for the just-removed packages
					cartAndWebSessionWithExpirationsQuery.refetch();

					onSuccess?.(data);

					data.packagesRemoved?.forEach((removedPackage) => {
						try {
							trackGA4EcommerceRemoveFromCart({
								event: "remove_from_cart",
								ecommerce: {
									items: [
										{
											currency: "AUD",
											item_id: String(removedPackage.packageId),
											item_name: removedPackage.title,
											item_brand: "Mona Foma",
											item_category: "Package",
											item_variant: removedPackage.priceTypeDescription,
											price: Number(removedPackage.price),
											quantity: removedPackage.quantity || 1,
										},
									],
								},
								...calculateCartExpirationStats(
									cartAndWebSessionWithExpirationsQuery.data
										?.webSessionWithExpirations,
								),
							});
						} catch (error) {
							Sentry.captureException(error, {
								extra: { info: data.packagesRemoved },
							});
						}
					});
				}
			},
			onError: showSiteError,
		},
	);
}

export function useFetchPaymentComponentQuery(
	{
		amountInCents,
		constituentId,
	}: z.infer<typeof ApiTessituraPaymentComponentRequestSchema>,
	options: UseQueryOptions<
		Awaited<z.infer<typeof ApiTessituraPaymentComponentResponseSchema>>
	> = {},
) {
	return useQuery<
		z.infer<typeof ApiTessituraPaymentComponentResponseSchema>,
		Error
	>(
		[`cart-payment-component`, amountInCents, constituentId],
		async () => {
			const response = await betterFetch("/api/tessitura/payment/component", {
				body: { amountInCents, constituentId },
			});

			return ApiTessituraPaymentComponentResponseSchema.parse(response);
		},
		{
			// Retry N times after a failure
			retry: 3,

			// `staleTime` sets how long in milliseconds until fetched data is
			// considered stale, after which time an action like query re-mount or
			// window focus will trigger a refetch
			staleTime: 60000,

			// `refetchInterval` sets the query to automatically refetch every N
			// milliseconds. Refetch will not happen if browser window is in the
			// background, unless `refetchIntervalInBackground` is set to true.
			refetchInterval: 60000,

			// Overrides
			...options,
		},
	);
}

/**
 * Perform checkout with Tessitura Merchant Services (TMS) with either a 1-step
 * or 2-step payment flow, depending on how this hook is configured.
 *
 * The `paymentStep` parameter determines what calling `mutate()` on this hook
 * will do, one of:
 *
 * - "login": Log user in as new or existing constituent; no purchase step.
 *
 *    Calls our middleware API to create or update a constituent in Tessitura
 *    based on the constituent information provided (name, address etc)
 *
 * - "purchase": Perform a purchase; no login step, but the user must already
 *   be logged in.
 *
 *   If payment is required, trigger the TMS payment component widget via the
 *   `tmsPaymentComponentRef` ref. The TMS component will then either show an
 *   error message, or redirect to the receipt page.
 *
 *   If no payment is required, call our middleware API to complete a
 *   zero-value payment. On success, the user will be redirected to a receipt
 *   page.
 *
 * - "both": Perform both of the log-in then the purchase steps above, for a
 *   1-step flow.
 */
export function usePerformTMSCheckoutMutation({
	amount,
	tmsPaymentComponentRef,
	paymentStep,
	onSuccess,
}: {
	amount: number;
	tmsPaymentComponentRef: RefObject<typeof window.TMSPaymentComponent>;
	paymentStep: z.infer<typeof PaymentStepSchema>;
	onSuccess?: (
		data: z.infer<typeof ApiTessituraPaymentZeroCheckoutResponseSchema> & {
			paymentStep: z.infer<typeof PaymentStepSchema>;
			constituentId: number | null;
		},
	) => void;
}) {
	const { showSiteError } = useSiteErrorActions();

	const cartAndWebSessionWithExpirationsQuery =
		useCartAndWebSessionWithExpirationsQuery();

	return useMutation<
		z.infer<typeof ApiTessituraPaymentZeroCheckoutResponseSchema> & {
			paymentStep: z.infer<typeof PaymentStepSchema>;
			constituentId: number | null;
		},
		TBetterError,
		z.infer<typeof ApiTessituraPaymentLoginRequestSchema>,
		unknown
	>(
		async (constituentInfo) => {
			let constituentId: number | null = null;

			// Log constituent in, to new or existing constituent record
			if (paymentStep === "login" || paymentStep === "both") {
				// TODO Fix schemas so we can do full parse validation
				const constituent = await betterFetch<{ Id: number }>(
					"/api/tessitura/payment/login",
					{
						method: "POST",
						body: constituentInfo,
					},
				);
				constituentId = constituent.Id;
			}
			// Fetch constituent information for logged-in constituent
			else {
				// TODO Fix schemas so we can do full parse validation
				const constituent = await betterFetch<{ Id: number }>(
					"/api/tessitura/payment/login",
					{
						method: "GET",
					},
				);
				constituentId = constituent.Id;
			}

			if (paymentStep === "purchase" || paymentStep === "both") {
				// If payment is required, submit the TMS payment component widget
				if (amount > 0) {
					tmsPaymentComponentRef.current?.submit();
				}
				// If payment is not required, complete a zero-value checkout
				else {
					const zeroCheckoutData: z.infer<
						typeof ApiTessituraPaymentZeroCheckoutResponseSchema
					> = await betterFetch("/api/tessitura/payment/zero-checkout", {
						method: "POST",
					});

					return {
						...zeroCheckoutData,
						paymentStep,
						constituentId,
					};
				}
			}

			return { paymentStep, constituentId, paymentCompleted: false };
		},
		{
			onSuccess: (result) => {
				onSuccess?.(result);

				// When payment complete (only happens for zero checkout)
				if (result.paymentCompleted) {
					// Trigger refresh of web cart a short time after successful checkout

					// NOTE: It can take a second or two for Tessitura to flag a
					// completed web session as "Complete" when a cart is completed, so
					// an immediate re-fetch won't necessarily get the completed status.
					// BUT because the /api/tessitura/cart/checkout action also deletes
					// the user's session cookie, this refresh is effective in fetching
					// now-empty session & cart data to update UI elements, e.g. hide the
					// cart button. We add a short delay so the browser has a chance to
					// navigate to the receipt page *before* the cart is refreshed to
					// show it as empty.
					setTimeout(() => {
						cartAndWebSessionWithExpirationsQuery.refetch();
					}, 2000);

					// Track purchase complete event
					// TODO Need to find a way to do this via the TMS payment widget
					try {
						trackPurchaseComplete(
							result.monaCartPostCheckout,
							cartAndWebSessionWithExpirationsQuery.data
								?.webSessionWithExpirations,
						);
					} catch (error) {
						Sentry.captureException(error, {
							extra: { info: { cart: result.monaCartPostCheckout } },
						});
					}

					// Redirect user to the receipt page. This will only happen when the
					// zero-checkout endpoint is used when there is no payment required.
					// When payment is required, the TMS payment component widget is
					// responsible for redirecting to the receipt page.
					window.location.href = result.receiptUrl;
				}
			},
			onError: showSiteError,
		},
	);
}

export function trackPurchaseComplete(
	monaCart: z.infer<typeof MonaCartSchema>,
	webSessionWithExpirations?: z.infer<
		typeof WebSessionWithExpirationsSchema
	> | null,
) {
	const gaItems: Array<GA4EcommerceItem> = [];

	monaCart.products.forEach((product) => {
		if (product.type === "package") {
			product.lineItemsWithPerformance.forEach((lineItem) => {
				lineItem.subLineItems.forEach((subLineItem) => {
					gaItems.push({
						currency: "AUD",
						item_id: String(lineItem.performance.id),
						item_name: lineItem.performance.description,
						item_brand: "Mona Foma",
						item_category: product.type,
						item_variant: String(subLineItem.monaPriceTypeName),
						price: subLineItem.amountToPay,
						quantity: 1,
					});
				});
			});
		} else if (product.type === "performance") {
			// For performances, group sub-line items by price type to track related
			// items together e.g. 2 x Standard tickets instead of one item for each
			const subLineItemsListByPriceType: Record<
				string,
				Array<(typeof product.subLineItems)[0]>
			> = arrayToListsObjectWithFunction<(typeof product.subLineItems)[0]>(
				product.subLineItems,
				(subLineItem) => String(subLineItem.priceType.id),
			);

			for (const subLineItemsList of Object.values(
				subLineItemsListByPriceType,
			)) {
				gaItems.push({
					currency: "AUD",
					item_id: String(product.id),
					item_name: product.description,
					item_brand: "Mona Foma",
					item_category: product.type,
					item_variant:
						subLineItemsList[0].zone.description +
						" " +
						(subLineItemsList[0].priceType.aliasDescription ||
							subLineItemsList[0].priceType.description),
					price: subLineItemsList[0].amountToPay,
					quantity: subLineItemsList.length,
				});
			}
		}
	});

	trackGA4EcommercePurchase({
		event: "purchase",
		ecommerce: {
			currency: "AUD",
			transaction_id: String(monaCart.orderNumber),
			value: monaCart.amountInPayments,
			tax: monaCart.amountInPayments / 10,
			items: gaItems,
		},
		...calculateCartExpirationStats(webSessionWithExpirations),
	});
}

export function useApplyGiftCardMutation({
	onSuccess,
}: {
	onSuccess?: () => void;
} = {}) {
	const cartAndWebSessionWithExpirationsQuery =
		useCartAndWebSessionWithExpirationsQuery();

	return useMutation<
		z.infer<typeof ApiTessituraCartGiftCardResponseSchema>,
		FetchError,
		z.infer<typeof ApiTessituraCartGiftCardRequestSchema>,
		unknown
	>(
		async (data) => {
			const response = await betterFetch("/api/tessitura/cart/gift-card", {
				body: data,
			});

			return ApiTessituraCartGiftCardResponseSchema.parse(response);
		},
		{
			onSuccess: () => {
				// Trigger refresh of web cart
				cartAndWebSessionWithExpirationsQuery.refetch();

				onSuccess?.();

				// TODO: Track gift card applied
			},
		},
	);
}

export function useUnapplyGiftCardMutation({
	onSuccess,
}: {
	onSuccess?: () => void;
} = {}) {
	const cartAndWebSessionWithExpirationsQuery =
		useCartAndWebSessionWithExpirationsQuery();

	return useMutation<
		z.infer<typeof ApiTessituraCartGiftCardResponseSchema>,
		FetchError,
		z.infer<typeof ApiTessituraCartGiftCardRequestSchema>,
		unknown
	>(
		async (data) => {
			const response = await betterFetch(
				"/api/tessitura/cart/gift-card/" + data.giftCardCode,
				{
					method: "DELETE",
				},
			);

			return ApiTessituraCartGiftCardResponseSchema.parse(response);
		},
		{
			onSuccess: () => {
				// Trigger refresh of web cart
				cartAndWebSessionWithExpirationsQuery.refetch();

				onSuccess?.();

				// TODO: Track gift card unapplied
			},
		},
	);
}

export function useApplyPromoCodeCodeMutation({
	onSuccess,
}: {
	onSuccess?: () => void;
} = {}) {
	const cartAndWebSessionWithExpirationsQuery =
		useCartAndWebSessionWithExpirationsQuery();

	return useMutation<
		z.infer<typeof ApiTessituraCartPromoCodeResponseSchema>,
		FetchError,
		z.infer<typeof ApiTessituraCartPromoCodeRequestSchema>,
		unknown
	>(
		async (data) => {
			const response = await betterFetch("/api/tessitura/cart/promo-code", {
				body: data,
			});

			return ApiTessituraCartPromoCodeResponseSchema.parse(response);
		},
		{
			onSuccess: () => {
				// Trigger refresh of web cart
				cartAndWebSessionWithExpirationsQuery.refetch();

				onSuccess?.();

				// TODO: Track discount code applied
			},
		},
	);
}

async function fetchStatesForCountryId(countryId: number) {
	const response = await betterFetch("/api/tessitura/states", {
		body: { CountryId: countryId },
	});
	return ApiTessituraStatesResponseSchema.parse(response);
}

export function useStatesForCountryIdQuery(
	{
		countryId,
	}: {
		countryId: number;
	},
	options: UseQueryOptions<
		Awaited<ReturnType<typeof fetchStatesForCountryId>>
	> = {},
) {
	return useQuery(
		["states-for-country-" + countryId],
		async () => fetchStatesForCountryId(countryId),
		{
			retry: 3,
			...options,
		},
	);
}
