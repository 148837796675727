export const TESSITURA_DEFAULT_MODE_OF_SALE_ID = 50;

export const TESSITURA_MONA_FOMA_2024_SEASON_ID = 125;

export const TESSITURA_MONA_FACILITY_ID = 482;

export const TESSITURA_FAUX_MO_FACILITY_ID = 161;

export const TESSITURA_COUNTRY_AUSTRALIA_ID = 227;

export const TESSITURA_STATE_TASMANIA_ID = 83;

export const TESSITURA_PRICE_TYPE_IDS = {
	UNDER_4: 295,

	UNDER_4_TRANSPORT: 9,

	COMPANION_CARD: 357,

	TRANSPORT_STANDARD_RETURN: 41, // Standard return,
};

export const TESSITURA_TRANSPORT_ZONE_IDS = {
	TRANSPORT_STANDARD_RETURN: 5029,

	RETURN_FROM_MONA_SESSIONS: 5029, // NOTE: Same as standard transport return

	RETURN_FROM_FAUX_MO: 5030,
};

export const TESSITURA_TRANSPORT_ZONE_MAP_IDS = {
	BUS_TO_FAUX_MO: 1196, // "MF24 - Bus to Faux Mo"

	BUS_FROM_SESSIONS_AND_FAUX_MO: 1159, // "MOFO2024 - Sessions + FauxMo"
};

export const TESSITURA_TYPE_IDS = {
	MERCH: 23,
};

export const TESSITURA_WEB_CONTENT_TYPE_IDS = {
	TN_EXPRESS_TITLE: 19, // TN_Express_Title
	TN_EXPRESS_PROD_IMG: 14, // TN_Express_Prod_Img
};

export const TESSITURA_CART_EXPIRATION_MINUTES = 20;

export const TESSITURA_TMS_PAYMENT_REFUSED_MESSAGE =
	"There was an issue processing your payment. Please check your details and try again.";

export const TESSITURA_TMS_PAYMENT_SESSION_COOKIE_NAME =
	"TESSITURA_PAYMENT_SESSION";

// TODO: Scrap this cookie altogether if we ditch single-step TMS checkout
export const TESSITURA_TMS_PAYMENT_STATUS_COOKIE_NAME =
	"TESSITURA_PAYMENT_STATUS";

// Payment method to use for calls to TMS Authorize API:
// "TREX Generic Hosted Payments" (TREXGen) in the "MONA Web TREX" payment method group
export const TESSITURA_TMS_PAYMENT_PAYMENT_METHOD_ID = 105;

export const TESSITURA_TMS_PAYMENT_REDIRECT_RETURN_PATH =
	"/api/tessitura/payment/redirect-return/";

export const TESSITURA_TMS_PAYMENT_CART_PATH = "/tickets/cart/";

export const TESSITURA_TMS_PAYMENT_RECEIPT_PATH = "/tickets/receipt/";

export const TESSITURA_TMS_PAYMENT_TRANSACTION_ORIGIN = "Mona Website";

// https://bitbucket.org/TN_WebShare/tessituramerchantservicesintegrationsample/src/development/#markdown-header-paymentcomponent-request
export const TESSITURA_TMS_PAYMENT_CARD_BRANDS_TO_ACCEPT: Array<
	| "amex"
	| "applepay"
	| "cup"
	| "diners"
	| "discover"
	| "interact_card"
	| "jcb"
	| "maestro"
	| "mc"
	| "paywithgoogle"
	| "visa"
> = ["mc", "visa", "amex", "applepay"];

// https://bitbucket.org/TN_WebShare/tessituramerchantservicesintegrationsample/src/development/#markdown-header-payment-component-configuration
export const TESSITURA_TMS_PAYMENT_COMPONENT_LOCALE = "en-AU";
export const TESSITURA_TMS_PAYMENT_COMPONENT_AUTHORIZE_ENDPOINT =
	"/api/tessitura/payment/authorize";
export const TESSITURA_TMS_PAYMENT_COMPONENT_FINALIZE_ENDPOINT =
	"/api/tessitura/payment/finalize";
export const TESSITURA_TMS_PAYMENT_COMPONENT_ENABLE_STORED_CARDS = true;

export const MONA_TRANSPORT_SEASON_IDS = [
	117, //2023/2024 Ferry
	118, //2023/2024 Bus
];

export const TESSITURA_STATUS_DESCRIPTIONS_TO_EXCLUDE = ["Off Sale"];

export const TESSITURA_SESSION_KEY_COOKIE_NAME = "TESSITURA_SESSION_KEY";

export const TESSITURA_SOURCE_ID_COOKIE_NAME = "MF_SOURCE_ID";
export const TESSITURA_SOURCE_ID_EXPIRY_DAYS = 2; // 48 hours

export const TESSITURA_DELIVERY_METHOD_ID = 9;

export const TESSITURA_ORGANIZATION = "Festivals Web"; // Others: "Impresario", "Tessitura Web"

export const TESSITURA_CHANNEL_ID = 11;

export const TESSITURA_SOLICITOR = "restweb";

export const TESSITURA_ORDER_CONFIRMATION_EMAIL_PROFILE_ID = 2; // IMPRESARIO_ORDER

export const TESSITURA_ORDER_CONFIRMATION_EMAIL_TEMPLATE_ID = 20; // Mona Foma order confirmation

export const MONA_SUBSCRIBE_API_URL =
	"https://subscribe.mona.net.au/api/v1/subscribe";

export const TRACKING_SEEN_ORDER_IDS_COOKIE_NAME = "MF_SEEN_ORDER_IDS";
