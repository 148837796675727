/* eslint-disable @typescript-eslint/no-explicit-any */

export interface BetterErrorOptions {
	info?: any;
	cause?: Error;
}

export interface TBetterError extends Error {
	info?: any;
	cause?: Error;
	fullStack?: string;
}

/**
 * Improved `Error` class:
 * - reports its `name` accurately when subclassed
 * - accpets extra optional parameters:
 *   - `cause` to capture the ancestor/original error
 *   - `info` to capture arbitrary additional information that might be useful
 *     downstream to debug, log, or recover from the error
 * - `fullStack()` function returns a text description including all ancestor
 *   errors captured with the `cause` parameter.
 */
export class BetterError extends Error {
	readonly info?: any;
	readonly cause?: Error;

	constructor(message, { info, cause }: BetterErrorOptions = {}) {
		super(message);

		this.info = info;

		if (!!cause && !(cause instanceof Error)) {
			throw new Error("Optional 'cause' argument must be an Error class");
		}
		this.cause = cause;
	}

	/**
	 * Return the real name of this error class, or subclasses, in text
	 * representations instead of just 'Error'
	 */
	get name() {
		// WARNING This is only a *somewhat* reliable way to get the real class name
		return this.constructor.name;
	}

	/**
	 * Return this error's `stack` text and also include the `stack` output of
	 * any nested `cause` errors.
	 */
	get fullStack() {
		let stackText = this.stack;
		let cause = this.cause;
		while (cause) {
			stackText += `\nCaused by: ${cause.stack || cause}`;
			cause = cause.cause as Error;
		}
		return stackText;
	}
}

/**
 * Represent an error that was the fault of the client/user/browser, not the
 * server, such as invalid data provided; in other words, any 4xx-like error.
 */
export class ClientError extends BetterError {}

/**
 * Represent an error that was the fault of the server, not the client; in
 * other words, any 5xx-like error.
 */
export class ServerError extends BetterError {}

export function simplifyErrorData(error) {
	if (!error) {
		return;
	}

	const info = error.info || {};

	// Strip `request` and `response` from Axios errors to avoid leaking
	// sensitive data like credentials or internal details
	delete info.request;
	delete info.response;

	// Simplify message for Tessitura errors to make them more human-readable.
	let tessituraErrorMessage = "";
	// Tessitura errors include an unusual `ErrorPath` property
	if (
		info.data?.[0] &&
		Object.prototype.hasOwnProperty.call(info.data[0], "ErrorPath")
	) {
		tessituraErrorMessage =
			// Include Tessitura's error code as message prefix if it's available
			(info.data[0].Code ? info.data[0].Code + " " : "") +
			// Include Tessitura's description of the error, for whatever it's worth
			info.data[0].Description;
	}

	return {
		type: error.name,
		message: tessituraErrorMessage || error.message,
		info,
		cause: simplifyErrorData(error.cause),
	};
}
