import fetchRetryWrapper, { RequestInitRetryParams } from "fetch-retry";

import { BetterError } from "./errors";

// Custom error class for fetch errors triggered by this module
export class FetchError extends BetterError {}

export interface BetterFetchRequestInit extends Omit<RequestInit, "body"> {
	timeout?: number;
	parseFunctionName?: "json" | "text" | "formData" | "blob" | "arrayBuffer";
	body?: string | object | BodyInit;
	params?: Record<string, string | number | boolean | undefined | null>;

	// Add retry parameters, as implemented by `fetch-retry`
	retries?: RequestInitRetryParams["retries"];
	retryDelay?: RequestInitRetryParams["retryDelay"];
	// Override `retryOn` to allow us to pass our own method signature where a
	// higher-order function takes the request method and retries max count
	// first, then returns the standard `fetch-retry` retryOn function. This is
	// necessary for us to have sufficient control over retries, due to design
	// flaws in the `fetch-retry` implementation.
	retryOn?: (
		method: string,
		retries: number,
	) => RequestInitRetryParams["retryOn"];
}

// Wrap system `fetch` with `fetch-retry` to add support for retries.
const fetchWithRetry = fetchRetryWrapper(fetch);

/**
 * An improved version of the `fetch` HTTP/S client function with request
 * timeouts and automatic handling of JSON-bearing interactions.
 *
 * Returns the parsed response data from a request or raises a `FetchError`.
 *
 * Accepts the standard `(url, options)` arguments as the normal `fetch` but
 * supports some extras and does special handling of others...
 *
 * Extra options:
 *
 * - `timeout`: The request timeout in milliseconds (optional). Default 60000
 *   (60 seconds).
 * - `parseFunctionName`: Name of the function to parse the response object:
 *   `json`, `text`, `formData`, `blob`, or `arrayBuffer` (optional). Default
 *   is `json`.
 * - `retries`: Number of times to retry the request on failure (optional).
 *    Default is 2.
 * - `retryDelay`: Function to calculate the delay between retries (optional).
 *    Default is an exponential back-off using `RETRY_DELAY_EXPONENTIAL`.
 * - `retryOn`: Function to determine whether to retry a failed request
 *   (optional). Default is to only retry idempotent requests that are
 *   inherently safe, as implemented in `RETRY_ON_WHEN_SAFE`.
 *
 * Options with special handling:
 *
 * - `headers`: Headers for request (optional). Default is "Content-Type" and
 *   "Accept" headers to "application/json".
 * - `body`: Request body payload (optional). Automatically serialized as JSON
 *   if it is an object.
 * - `params`: GET parameters object (optional). Convert an object to encoded
 *   GET parameters and append them to the URL.
 * - `method`: HTTP method for request (optional). Default is "POST" if `body`
 *   is provided, otherwise "GET".
 *
 *
 * A `FetchError` is raised if the request fails due to:
 *
 * - an unexpected response status like a 400 client error or 500 server error.
 *   In this case the error object includes the field `info.response` with the
 *   full response object.
 * - a low-level unrecoverable failure with networking, timeout etc. In this
 *   case the error object includes the fields `cause` with the original error
 *   and `info.response` with the response object.
 *
 * @param {string} url
 * @param {*} options
 * @param {*} initialData Default data to return until request completes
 * (optional).
 * @param {string} parseFunctionName Name of the function to call on the
 * `fetch` response object to read result data: `json`, `text`, `formData`,
 * `blob`, `arrayBuffer` (optional). Default is `json`.
 */
export async function betterFetch<T>(
	url: string,
	options: BetterFetchRequestInit = {},
): Promise<T> {
	// Apply a timeout to fetch requests, 60 seconds by default
	const { timeout = 60000 } = options;
	let controller: AbortController | undefined = undefined;
	let controllerTimeoutId: NodeJS.Timeout | undefined = undefined;
	try {
		controller = new AbortController();
		controllerTimeoutId = setTimeout(() => controller?.abort(), timeout);
	} catch (error) {
		// If we can't create an abort controller, give up: we'll just do without
		// timeouts. This could happen in old browsers
		console.error(`Timeout ${timeout} is not supported: ${error.message}`);
	}

	let { body } = options;

	// Set default headers depending on whether we are sending Form or JSON data,
	// but always expect a JSON response by default
	const {
		headers = body instanceof FormData
			? {
					"Content-Type": "application/x-www-form-urlencoded",
					Accept: "application/json",
			  }
			: {
					"Content-Type": "application/json",
					Accept: "application/json",
			  },
	} = options;

	if (body instanceof FormData) {
		// Nothing to do
	}
	// Automatically serialize body as JSON if necessary for a JSON-bearing request
	else if (body && typeof body !== "string") {
		body = JSON.stringify(body);
	}

	// Convert `params` object option to URL-encoded GET parameters
	const { params } = options;
	if (params) {
		const encodedParams = Object.keys(params)
			.reduce<Array<string>>((list, name) => {
				const value = params[name];
				if (value) {
					list.push(`${name}=${encodeURIComponent(value)}`);
				}
				return list;
			}, [])
			.join("&");
		url += url.indexOf("?") >= 0 ? "&" : "?";
		url += encodedParams;
	}

	// Extract key options for improved error messages
	const { method = body ? "POST" : "GET" } = options;

	// Name of function called on response to parse its data payload
	const { parseFunctionName = "json" } = options;

	let response;
	try {
		const { retries, retryDelay, retryOn } = options;

		response = await fetchWithRetry(url, {
			// Apply request timeout with signal
			signal: controller?.signal,
			// Pass through all given options
			...options,
			// Apply default options and overrides
			method,
			headers,
			body: body as string,

			// Apply retry options
			// Note: We apply `retries` indirectly via the `retryOn` function here,
			// because the `fetch-retry` implementation unfortunately ignores
			// `retries` if you also supply a custom `retryOn` function.
			retryDelay: retryDelay ?? RETRY_DELAY_EXPONENTIAL,
			retryOn: retryOn
				? retryOn(method, retries ?? 2)
				: RETRY_ON_WHEN_SAFE(method, retries ?? 2),
		});

		// Clear request timeout as soon as we get a response: we want the timeout
		// to apply to the request phase only, not the response reading phase
		clearTimeout(controllerTimeoutId);

		// Parse error response body, whether or not the response is considered OK
		let responseData;
		let responseDataError;
		try {
			// When parsing JSON, parse as text first then convert to JSON so we can
			// return `undefined` for an empty body instead of failing with:
			//     SyntaxError: Unexpected end of JSON input
			// TODO Is there a better way to detect an empty response body?
			if (parseFunctionName === "json") {
				const text = await response.text();
				responseData = text ? JSON.parse(text) : undefined;
			} else {
				responseData = await response[parseFunctionName]();
			}
		} catch (errorParsingErrorResponseData) {
			responseDataError = errorParsingErrorResponseData;
		}

		// Raise error if response is not a success, and include the `response`
		// object with the error in case caller can extract useful info from it
		if (!response.ok) {
			throw new FetchError(
				`Fetch invalid response for ${method} '${url}': ` +
					`${response.status} ${response.statusText}`,
				{
					info: {
						status: response.status,
						statusText: response.statusText,
						response,
						data: responseData,
					},
				},
			);
		}

		// Return parsed response data if we have it, raise the parsing error if we don't
		if (responseDataError) {
			throw responseDataError;
		} else {
			return responseData;
		}
	} catch (error) {
		if (error instanceof FetchError) {
			throw error;
		}

		throw new FetchError(`Fetch failed for ${method} '${url}': ${error}`, {
			cause: error,
			info: { response: response },
		});
	} finally {
		// Clear request timeout if it was not cleared by now
		clearTimeout(controllerTimeoutId);
	}
}

/**
 * Exponential backoff retry delay function for `fetch-retry`, returns
 * increasing delay for each subsequent attempt: 0s, 0.5s, 1.5s, 3.5s, 7.5s,
 * etc.
 */
export const RETRY_DELAY_EXPONENTIAL = (
	attempt: number,
	error: Error | null,
	response: Response | null,
) => {
	return ((Math.pow(2, attempt) - 1) / 2) * 1000;
};

/**
 * Retry fetch requests only when it is safe to do so because:
 * - the request is idempotent (GET, HEAD, OPTIONS, PUT, DELETE)
 * - OR, the response status code is 100-199, 429, 500-599
 *
 * NOTE: The default behaviour of the `fetch-retry` library to retry on any
 * network error does not seem safe to me.
 *
 * NOTE: Unfortunately the `fetch-retry` library implementation of this method
 * does not give us access to the request's method, so we must make this a
 * higher-order function that takes the request method first and pass that in
 * at runtime.
 */
export const RETRY_ON_WHEN_SAFE = (requestMethod: string, retries: number) => {
	function inner(
		attempt: number,
		error: Error | null,
		response: Response | null,
	) {
		// Do NOT retry successful requests
		if (response?.ok) {
			return false;
		}
		// Do NOT retry if we have reached the maximum number of retries
		if (attempt >= retries) {
			return false;
		}

		// Always retry idempotent requests, i.e. not POST
		if (
			["GET", "HEAD", "OPTIONS", "PUT", "DELETE"].includes(
				requestMethod?.toUpperCase(),
			)
		) {
			return true;
		}

		// Only retry specific response status codes for non-idempotent requests
		const status = response?.status ?? 0;
		if (
			(status >= 100 && status <= 199) ||
			status === 429 || // Rate limiting
			(status >= 500 && status <= 599)
		) {
			return true;
		}

		return false;
	}

	return inner;
};

/**
 * Retry failed fetch requests in all cases. Only use this when you are
 * ABSOLUTELY SURE it is safe to retry failed requests, such as for POST
 * requests that really function as reads behind the scenes (like custom DB
 * query executions in Tessitura).
 */
export const RETRY_ON_ALWAYS = (requestMethod: string, retries: number) => {
	function inner(
		attempt: number,
		error: Error | null,
		response: Response | null,
	) {
		// Do NOT retry successful requests
		if (response?.ok) {
			return false;
		}
		// Do NOT retry if we have reached the maximum number of retries
		if (attempt >= retries) {
			return false;
		}

		return true;
	}

	return inner;
};
