import { z } from "zod";
import { clientEnv } from "./clientSide/env.client.mjs";
import { logErrorToSentryAsWarning } from "./sentry";

/**
 * Parse and return data according to a given Zod schema, but on parse failure
 * just log a warning (to Sentry & console) unless a truthy `strict` parameter
 * is given or the envvar `NEXT_PUBLIC_ZOD_PARSE_STRICT` is set.
 *
 * NOTE: You should probably just use `Schema.parse()` directly instead of
 * passing a truthy `strict` parameter to this function.
 *
 * Hat tip to https://stackoverflow.com/a/75022216/4970 for z.AnyZodObject
 *
 * @param schema Zod schema to use for parsing
 * @param data Data to parse
 * @returns The parsed data, or the original data if parsing failed and neither
 * the `strict` parameter or envvar `NEXT_PUBLIC_ZOD_PARSE_STRICT` are set.
 */

export function zodSaferParse<S extends z.ZodSchema>(
	schema: S,
	data: unknown,
	strict = false,
): z.infer<S> {
	try {
		return schema.parse(data);
	} catch (error) {
		if (strict || clientEnv.NEXT_PUBLIC_ZOD_PARSE_STRICT) {
			throw error;
		} else {
			console.warn(error);
			logErrorToSentryAsWarning(error);

			return data;
		}
	}
}
