import { z } from "zod";

import {
	ApiIdSchema,
	ConstituentDetailSchema,
	CountrySchema,
	CurrencyAmountSchema,
	DateishSchema,
	EntitySchema,
	IntegerSchema,
	PackageDetailSchema,
	PackageSchema,
	PaymentComponentSchema,
	PerformanceSchema,
	PriceSummarySchema,
	ProductionSeasonSchema,
	StateSchema,
	TypeSummarySchema,
	UrlSchema,
	WebSessionSchema,
} from "./tessituraSchemas";
import { zodSaferParse } from "./zod";

/**
 * Return a JSON serialized string of data that complies with a given Zod
 * schema. If data does not match the schema at runtime just log a warning (to
 * Sentry & console) unless the envvar `NEXT_PUBLIC_ZOD_PARSE_STRICT` is set.
 */
export function jsonStringifySchemaValue<S extends z.ZodSchema>(
	schema: S,
	value: z.infer<S>,
): string {
	const parsedValue = zodSaferParse(schema, value);
	return JSON.stringify(parsedValue);
}

export const CoerceIntegerSchema = z.coerce.number().int();

export const DateYMDSchema = z
	.string()
	.regex(/^\d{4}-\d{2}-\d{2}$/, `Required format: YYYY-MM-DD`)
	.describe("DateYMDSchema");

export const WebSessionWithExpirationsSchema = WebSessionSchema.extend({
	isCompleted: z.boolean(),
	SessionExpiration: DateishSchema.optional(),
	CartExpiration: DateishSchema.optional(),
	CartCreation: DateishSchema.optional(),
});

export const TicketOptionSchema = z
	.object({
		// Fields we derive or calculate
		adjustedZoneTime: z.string(),
		adjustedZoneTimeAsDateISO: z.string().datetime({ offset: true }).nullish(),
		zoneTimeAsDateISO: z.string().datetime({ offset: true }).nullish(),
		isExcludedFromWeb: z.boolean(),
		code: z.string(),
		description: z.string(),
		shortName: z.string().nullish(),
		// Fields mapped directly from Tessitura API response objects
		item: z.discriminatedUnion("type", [
			z.object({
				type: z.literal("package"),
				packageId: ApiIdSchema,
				performanceGroupId: ApiIdSchema,
				performanceIds: ApiIdSchema.array(),
			}),
			z.object({
				type: z.literal("performance"),
				performanceId: ApiIdSchema,
			}),
		]),
		priceTypeId: ApiIdSchema,
		zoneId: ApiIdSchema,
		availableCount: IntegerSchema,
		price: CurrencyAmountSchema,
		priceTypeDescription: z.string(),
		priceTypeAliasDescription: z.string().nullish(),
		zoneDescription: z.string(),
		zoneShortDescription: z.string(),
		zoneTime: z.string(),
		typeId: IntegerSchema,
		typeDescription: z.string().nullish(),
		isGA: z.boolean(),
	})
	.describe("TicketOptionSchema");

export const TicketOptionAndQuantitySchema = z.object({
	ticketOption: TicketOptionSchema,
	quantity: IntegerSchema,
});

export const PerformanceWithTicketOptionsSchema = PerformanceSchema.extend({
	ticketOptions: TicketOptionSchema.array(),
	webContentTitle: z.string().nullish(),
});

export const PackageWithTicketOptionsSchema = PackageSchema.extend({
	ticketOptions: TicketOptionSchema.array(),
});

export const PriceSummarySchemaWithWebExclusionSchema =
	PriceSummarySchema.extend({
		isExcludedFromWeb: z.boolean(),
	});

export const ProductionSeasonWithPerformancesSchema =
	ProductionSeasonSchema.extend({
		performances: PerformanceSchema.array(),
	});

export const _IdSchema = z.number().int().describe("IdSchema");

export const _DateSchema = z.string().datetime({ offset: true });

export const _IdAndDescriptionSchema = z
	.object({
		id: _IdSchema,
		description: z.string(),
	})
	.describe("IdAndDescriptionSchema");

export const MonaCartZoneSchema = _IdAndDescriptionSchema
	.extend({
		zoneGroupId: _IdSchema,
		shortDescription: z.string(),
		abbreviation: z.string(),
		zoneTime: z.string(),
	})
	.describe("MonaCartZoneSchema");

export const MonaCartSeatSchema = z
	.object({
		id: _IdSchema,
		row: z.string(),
		number: z.string(),
		section: _IdAndDescriptionSchema.extend({
			shortDescription: z.string(),
			printDescription: z.string().nullish(),
		}),

		monaSeatName: z.string(),
	})
	.describe("MonaCartSeatSchema");

export const MonaCartPriceTypeSchema = _IdAndDescriptionSchema
	.extend({
		aliasDescription: z.string(),
		shortDescription: z.string(),
		category: _IdAndDescriptionSchema,
		priceTypeReason: _IdAndDescriptionSchema.nullish(),
	})
	.describe("MonaCartPriceTypeSchema");

export const MonaCartPricingRuleSchema = _IdAndDescriptionSchema
	.extend({
		ruleAction: ApiIdSchema,
	})
	.describe("MonaCartPricingRuleSchema");

const _BaseMonaCartItemSchema = z
	.object({
		lineItemId: _IdSchema,

		id: _IdSchema,
		description: z.string(),
		amountToPay: z.number(),
	})
	.describe("_BaseMonaCartLineItemSchema");

export const MonaCartFeeSchema = _BaseMonaCartItemSchema
	.extend({
		category: _IdAndDescriptionSchema,
		originalAmount: z.number(),
		overideActive: z.boolean(),
		overrideAmount: z.number(),
	})
	.describe("MonaCartFeeSchema");

const _BaseMonaCartProductSchema = _BaseMonaCartItemSchema
	.extend({
		amountOriginallyDue: z.number(),
		productGrouping: z.literal("Flex").or(z.string()),
	})
	.describe("_BaseMonaCartProductSchema");

export const MonaCartProductSubLineItemSchema = z
	.object({
		id: _IdSchema,
		amountToPay: z.number(),
		amountAlreadyPaid: z.number(),

		zone: MonaCartZoneSchema,
		seat: MonaCartSeatSchema,

		priceType: MonaCartPriceTypeSchema,
		monaPriceTypeName: z.string(),

		originalPriceType: MonaCartPriceTypeSchema,
		monaOriginalPriceTypeName: z.string(),

		pricingRule: MonaCartPricingRuleSchema.nullish(),
		isPricingRuleApplied: z.boolean().nullish(),

		fees: MonaCartFeeSchema.array(),

		// SubLineItemDetails
		details: z
			.object({
				discountType: _IdAndDescriptionSchema.nullish(),
				originalPrice: z.number(),
			})
			.array(),
	})
	.describe("MonaCartProductSubLineItemSchema");

const _BaseMonaCartPerformanceSchema = z
	.object({
		id: _IdSchema,
		code: z.string(),
		description: z.string(),
		dateTime: _DateSchema,
		duration: z.number().int().nullish(),

		webContentTitle: z.string().nullish(),

		facility: z.object({
			id: _IdSchema,
			description: z.string(),
		}),
		theatre: z
			.object({
				id: _IdSchema,
				description: z.string(),
			})
			.nullish(),
		monaVenueName: z.string(),

		season: _IdAndDescriptionSchema,
		productionSeason: _IdAndDescriptionSchema,
	})
	.describe("_BaseMonaCartPerformanceSchema");

export const MonaCartProductPerformanceSchema = _BaseMonaCartProductSchema
	.merge(_BaseMonaCartPerformanceSchema)
	.extend({
		type: z.literal("performance"),

		lineItemId: _IdSchema,

		source: _IdAndDescriptionSchema,

		subLineItems: MonaCartProductSubLineItemSchema.array(),
		quantity: z.number().int(),
	})
	.describe("MonaCartProductPerformanceSchema");

export const MonaCartProductPackageLineItemSchema = z
	.object({
		id: _IdSchema,
		groupId: _IdSchema,

		performanceGroup: _IdAndDescriptionSchema.nullish(),
		performance: _BaseMonaCartPerformanceSchema,

		amountToPay: z.number(),

		subLineItems: MonaCartProductSubLineItemSchema.array(),
	})
	.describe("MonaCartProductPackageLineItemSchema");

export const MonaCartProductPackageSchema = _BaseMonaCartProductSchema
	.extend({
		type: z.literal("package"),

		code: z.string(),
		dateTime: _DateSchema,
		packageType: z.enum(["flex/nfs", "fixed", "super", "sub"]),

		season: _IdAndDescriptionSchema,
		source: _IdAndDescriptionSchema,

		lineItemsWithPerformance: MonaCartProductPackageLineItemSchema.array(),
		quantity: z.number().int(),
	})
	.describe("MonaCartProductPackageSchema");

export const MonaCartProductGiftCardSchema = _BaseMonaCartProductSchema
	.extend({
		type: z.literal("giftcard"),

		paymentId: _IdSchema,
		giftCardNumber: z.string(),
		notes: z.string(),
		name: z.string(),
	})
	.describe("MonaCartProductGiftCardSchema");

export const MonaCartProductContributionSchema = _BaseMonaCartProductSchema
	.extend({
		type: z.literal("contribution"),

		fund: _IdAndDescriptionSchema,
		notes: z.string(),
	})
	.describe("MonaCartProductContributionSchema");

export const MonaCartProductMembershipSchema = _BaseMonaCartProductSchema
	.extend({
		type: z.literal("membership"),

		fund: _IdAndDescriptionSchema,
		membershipOrganization: _IdAndDescriptionSchema,
		membershipLevel: _IdAndDescriptionSchema,
	})
	.describe("MonaCartProductMembershipSchema");

export const MonaCartProductUserDefinedFeeSchema = MonaCartFeeSchema.extend({
	type: z.literal("fee"),
}).describe("MonaCartProductUserDefinedFeeSchema");

export const MonaCartProductSchema = z
	.discriminatedUnion("type", [
		MonaCartProductPerformanceSchema,
		MonaCartProductPackageSchema,
		MonaCartProductGiftCardSchema,
		MonaCartProductContributionSchema,
		MonaCartProductMembershipSchema,
		MonaCartProductUserDefinedFeeSchema,
	])
	.describe("MonaCartProductSchema");

export const MonaCartPaymentSchema = z
	.object({
		id: _IdSchema,
		amount: z.number(),

		giftCardNumber: z.string(),
		notes: z.string(),

		paymentMethod: _IdAndDescriptionSchema.extend({
			paymentType: _IdAndDescriptionSchema,
			accountType: _IdAndDescriptionSchema.nullish(),
		}),
	})
	.describe("MonaCartPaymentSchema");

export const MonaCartSchema = z
	.object({
		sessionKey: z.string(),
		id: _IdSchema,
		orderNumber: _IdSchema,

		products: MonaCartProductSchema.array(),
		fees: MonaCartFeeSchema.array(),
		payments: MonaCartPaymentSchema.array(),

		amountToPay: z.number(),
		amountPaidPreviously: z.number(),
		amountInProducts: z.number(),
		amountInFees: z.number(),
		amountInPayments: z.number(),

		modeOfSale: _IdAndDescriptionSchema,
		deliveryMethod: _IdAndDescriptionSchema,
		source: _IdAndDescriptionSchema,

		solicitor: z.string(),
		notes: z.string(),

		constituent: z.object({
			id: _IdSchema,
			displayName: z.string(),

			email: z.string().nullish(),
			firstName: z.string().nullish(),
			lastName: z.string().nullish(),
			street: z.string().nullish(),
			city: z.string().nullish(),
			postcode: z.string().nullish(),
			phoneNumber: z.string().nullish(),
			stateId: _IdSchema.nullish(),
			countryId: _IdSchema.nullish(),
		}),

		orderDateTime: _DateSchema.nullish(),

		// Calculated
		quantity: IntegerSchema,
		quantityByPerformanceId: z.record(z.string(), z.number()),
		quantityByPackageId: z.record(z.string(), z.number()),
	})
	.describe("MonaCartSchema");

export const TSPackageDetailWithPerformancesSchema = PackageDetailSchema.extend(
	{
		performances: PerformanceSchema.array(),
	},
).describe("TSPackageDetailWithPerformancesSchema");

export const ZoneGroupSummarySchema = EntitySchema.extend({
	AliasDescription: z.string().nullish(),
	Rank: IntegerSchema,
}).describe("ZoneGroupSummarySchema");

export const ZoneSummarySchema = EntitySchema.extend({
	ShortDescription: z.string(),
	Rank: IntegerSchema,
	ZoneMapId: ApiIdSchema,
	ZoneTime: z.string(),
	Abbreviation: z.string(),
	ZoneLegend: z.string(),
	ZoneGroup: ZoneGroupSummarySchema,
}).describe("ZoneSummarySchema");

export const PerformanceZoneAvailabilitySchema = z
	.object({
		Zone: ZoneSummarySchema,
		SectionSummaries: TypeSummarySchema.array().nullish(),
		PerformanceId: ApiIdSchema.nullish(),
		AvailableCount: IntegerSchema,
		Inactive: z.boolean(),
	})
	.describe("PerformanceZoneAvailabilitySchema");

export const EntitySummarySchema = EntitySchema.extend({
	Description: z.string(),
}).describe("EntitySummarySchema");

export const BestSeatMapSummarySchema = EntitySummarySchema.extend({
	IsGA: z.boolean(),
}).describe("BestSeatMapSummarySchema");

export const MembershipOrganizationSummarySchema = EntitySummarySchema.extend({
	EditIndicator: z.boolean(),
}).describe("MembershipOrganizationSummarySchema");

export const ControlGroupSummarySchema = TypeSummarySchema.describe(
	"ControlGroupSummarySchema",
);

export const FacilitySummarySchema = EntitySummarySchema.extend({
	SeatMap: EntitySummarySchema,
}).describe("FacilitySummarySchema");

export const ConstituentGroupSummarySchema = TypeSummarySchema.describe(
	"ConstituentGroupSummarySchema",
);

export const RankTypeSummarySchema = TypeSummarySchema.extend({
	ControlGroup: ConstituentGroupSummarySchema,
}).describe("RankTypeSummarySchema");

export const SeasonSummarySchema = EntitySummarySchema.extend({
	FYear: IntegerSchema,
	Inactive: z.boolean(),
}).describe("SeasonSummarySchema");

export const TicketDesignSchema =
	EntitySummarySchema.describe("TicketDesignSchema");

export const PackageTypeSummarySchema = TypeSummarySchema.describe(
	"PackageTypeSummarySchema",
);

export const ZoneMapSummarySchema = TypeSummarySchema.extend({
	SeatMap: EntitySummarySchema,
}).describe("ZoneMapSummarySchema");

export const StringRecordSchema = z
	.record(z.string(), z.string())
	.describe("StringRecordSchema");

export const PackagePerformanceGroupDetailSchema = z
	.object({
		AvailableIndicator: z.boolean(),
		DisplayByZone: z.boolean(),
		Facility: z.string(),
		FixedSeatIndicator: z.boolean(),
		FlexIndicator: z.boolean(),
		GroupDescription: z.string(),
		GroupId: ApiIdSchema,
		GroupMaxPerformances: IntegerSchema,
		GroupMinPerformances: IntegerSchema,
		GroupRanking: IntegerSchema,
		Inactive: z.boolean(),
		MaxPerformances: IntegerSchema,
		MinPerformances: IntegerSchema,
		PackageDescription: z.string(),
		PackageId: ApiIdSchema,
		PerformanceCode: z.string(),
		PerformanceDateTime: DateishSchema,
		PerformanceDescription: z.string(),
		PerformanceId: ApiIdSchema,
		SalesNotes: z.string().nullish(),
		SalesNotesReadIndicator: z.boolean(),
		ZoneMapId: ApiIdSchema,
		Zones: PerformanceZoneAvailabilitySchema.array(),
	})
	.describe("PackagePerformanceGroupDetailSchema");

export const PriceTypeDetailSchema = z
	.object({
		PriceTypeId: ApiIdSchema,
		AliasDescription: z.string().nullish(),
		PerformanceId: ApiIdSchema,
		PackageId: ApiIdSchema,
		SuperPackageId: ApiIdSchema,
		Description: z.string(),
		ReasonIndicator: z.boolean(),
		ShortDescription: z.string(),
		DefaultIndicator: z.boolean(),
		IsBest: z.boolean(),
		Offer: z.boolean(),
		Rank: IntegerSchema,
		MaxSeats: IntegerSchema,
		Terms: z.string().nullish(),
		Zones: ZoneSummarySchema.array().nullish(),
	})
	.describe("PriceTypeDetailSchema");

export const PackageConsolidatedSchema = PackageSchema.extend({
	webContents: StringRecordSchema.nullish(),
	performancesInPackageGroupByDate: z.record(
		z.string(),
		PackagePerformanceGroupDetailSchema.array(),
	),
	priceTypesById: z.record(
		z.number().or(z.string()),
		PriceTypeDetailSchema.omit({ Zones: true, PerformanceId: true }).extend({
			validForPerformanceIds: z.number().array(),
		}),
	),
	performancePricesByPerformanceId: z.record(
		z.number().or(z.string()),
		PriceSummarySchema.array(),
	),
});

export const PackageConsolidatedWithTicketOptionsSchema =
	PackageConsolidatedSchema.extend({
		ticketOptions: TicketOptionSchema.array(),
	});

export const TMSPaymentSessionSchema = z.object({
	// Status gives the overall status of a TMS payment, with possible values:
	// - 'action' - the payment is in progress (technically a further action is
	//   required, but we overload this TMS-specific value to mean the payment as
	//   a whole is in-progress not just the credit card step)
	// - 'redirect' - the payment is complete and the user should be redirected
	//   to the receipt / confirmation page
	// - 'error' - the payment has failed and the user should be shown an error
	//   message or page.
	status: z.literal("action").or(z.literal("redirect")).or(z.literal("error")),

	// A unique identifier for the payment within Tessitura, as reserved during
	// the payment process
	paymentId: ApiIdSchema,

	// Did the user choose to save their credit card details for future use?
	storeCard: z.boolean().nullish(),
});

export const MonaCartAndWebSessionSchema = z.object({
	monaCart: MonaCartSchema.nullish(),
	webSessionWithExpirations: WebSessionWithExpirationsSchema.nullish(),
});

/* /api/tessitura/session */

export const ApiTessituraSessionResponseSchema =
	WebSessionWithExpirationsSchema;

/* /api/tessitura/payment/component */

export const ApiTessituraPaymentComponentRequestSchema = z.object({
	amountInCents: IntegerSchema,
	constituentId: ApiIdSchema.nullish(),
});

export const ApiTessituraPaymentComponentResponseSchema =
	PaymentComponentSchema.or(z.null());

export const PaymentStepSchema = z.enum(["login", "purchase", "both"]);

/* /api/tessitura/payment/login */

export const ConstituentDetailsFormSchema = z.object({
	email: z.string().email(),
	lastName: z.string(),
	firstName: z.string(),
	street: z.string(),
	city: z.string(),
	postcode: z.string(),
	countryId: ApiIdSchema,
	stateId: ApiIdSchema,
	phoneNumber: z.string(),
	acceptsMonaTerms: z.boolean(),
	subscribedToNewsletter: z.boolean().optional(),
});

export const ApiTessituraPaymentLoginRequestSchema =
	ConstituentDetailsFormSchema.extend({});

export const ApiTessituraPaymentLoginResponseSchema =
	ConstituentDetailSchema.extend({});

/* /api/tessitura/payment/authorize */

export const ApiTessituraPaymentAuthorizeRequestSchema = z.object({
	payload: z.string(),
	storeCard: z.boolean().nullish(),
});

export const ApiTessituraPaymentAuthorizeResponseSchema = z.discriminatedUnion(
	"type",
	[
		z.object({ type: z.literal("redirect"), redirect: UrlSchema }),
		z.object({ type: z.literal("action"), action: z.string() }),
		z.object({ type: z.literal("error"), error: z.string() }),
	],
);

/* /api/tessitura/payment/finalize */

export const ApiTessituraPaymentFinalizeRequestSchema = z.object({
	payload: z.string(),
});

export const ApiTessituraPaymentFinalizeResponseSchema =
	ApiTessituraPaymentAuthorizeResponseSchema;

/* /api/tessitura/payment/zero-checkout */

export const ApiTessituraPaymentZeroCheckoutRequestSchema = z.object({});

export const ApiTessituraPaymentZeroCheckoutResponseSchema =
	z.discriminatedUnion("paymentCompleted", [
		z.object({
			paymentCompleted: z.literal(true),
			receiptUrl: UrlSchema,
			monaCartPostCheckout: MonaCartSchema,
		}),
		z.object({ paymentCompleted: z.literal(false) }),
	]);

/* /api/tessitura/cart */

export const ApiTessituraCartResponseSchema = z.object({
	monaCart: MonaCartSchema.nullish(),
});

/* /api/tessitura/cart/add */

export const PerformanceToAddToCartSchema = z.object({
	performanceId: ApiIdSchema,
	priceTypeId: ApiIdSchema,
	priceTypeDescription: z.string(),
	zoneId: ApiIdSchema,
	quantity: IntegerSchema,
	title: z.string(),
	price: CurrencyAmountSchema,
});

export const PackageToAddToCartSchema = z.object({
	packageId: ApiIdSchema,
	performanceGroupId: ApiIdSchema,
	performanceIds: ApiIdSchema.array(),
	priceTypeId: ApiIdSchema,
	priceTypeDescription: z.string(),
	zoneId: ApiIdSchema,
	quantity: IntegerSchema,
	title: z.string(),
	price: CurrencyAmountSchema,
});

export const ApiTessituraCartAddRequestSchema = z.object({
	ModeOfSaleId: ApiIdSchema,
	performances: PerformanceToAddToCartSchema.array(),
	packages: PackageToAddToCartSchema.array(),
	Organization: z.string().optional(),
	SourceId: ApiIdSchema.optional(),
	permitNonContiguousSeats: z.boolean().nullish(),
});

export const PerformancesAddedToCartSchema =
	PerformanceToAddToCartSchema.extend({
		lineItemId: ApiIdSchema,
		seatsReserved: IntegerSchema,
	});

export const PackagesAddedToCartSchema = PackageToAddToCartSchema.extend({
	lineItemId: ApiIdSchema,
	seatsReserved: IntegerSchema,
});

export const ApiTessituraCartAddResponseSchema = z.object({
	performancesAdded: PerformancesAddedToCartSchema.array(),
	packagesAdded: PackagesAddedToCartSchema.array(),
	errorMessages: z.string().array(),
});

/* /api/tessitura/cart/remove */

export const PerformanceToRemoveFromCartSchema = z.object({
	performanceId: ApiIdSchema,
	lineItemId: ApiIdSchema,
});

export const PackageToRemoveFromCartSchema = z.object({
	packageId: ApiIdSchema,
	packageLineItemId: ApiIdSchema,
});

export const ApiTessituraCartRemoveRequestSchema = z.object({
	performances: PerformanceToRemoveFromCartSchema.array(),
	packages: PackageToRemoveFromCartSchema.array(),
});

export const PerformanceRemovedFromCartSchema =
	PerformanceToRemoveFromCartSchema.extend({
		title: z.string(),
		price: CurrencyAmountSchema,
		priceTypeDescription: z.string(),
		quantity: IntegerSchema.optional(),
	});

export const PackageRemovedFromCartSchema =
	PackageToRemoveFromCartSchema.extend({
		title: z.string(),
		price: CurrencyAmountSchema,
		priceTypeDescription: z.string(),
		quantity: IntegerSchema.optional(),
	});

export const ApiTessituraCartRemoveResponseSchema = z.object({
	performancesRemoved: PerformanceRemovedFromCartSchema.array(),
	packagesRemoved: PackageRemovedFromCartSchema.array(),
	errorMessages: z.string().array(),
});

/* /api/tessitura/cart/promo-code */

export const ApiTessituraCartPromoCodeRequestSchema = z.object({
	promoCode: z.string(),
});

export const ApiTessituraCartPromoCodeResponseSchema = z.object({
	promoCode: z.string(),
	applied: z.boolean(),
	pricingRulesAppliedCount: IntegerSchema,
	pricingRulesPreviouslyAppliedCount: IntegerSchema,
	text: z.string().nullish().array(),
});

/* /api/tessitura/cart/gift-card */

export const ApiTessituraCartGiftCardRequestSchema = z.object({
	giftCardCode: z.string(),
});

export const ApiTessituraCartGiftCardResponseSchema = z
	.null()
	.or(z.undefined());

/* /api/tessitura/countries */

export const ApiTessituraCountriesResponseSchema = CountrySchema.pick({
	Id: true,
	Description: true,
})
	.extend({
		isStateRequired: z.boolean(),
	})
	.array();

/* /api/tessitura/states */

export const ApiTessituraStatesRequestSchema = z.object({
	CountryId: ApiIdSchema,
});

export const ApiTessituraStatesResponseSchema = StateSchema.pick({
	Id: true,
	Description: true,
	StateCode: true,
}).array();
